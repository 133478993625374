import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {Box, Collapse, Grid, IconButton, TableCell, Typography} from '@mui/material'
import React from 'react'

import {useTableStyles} from './styles'
import {KeyFieldType, TableColumnType} from './types'

type MobileRowProps<R> = {
  columns: TableColumnType[]
  rowIndex: number
  row: R
  onRowClick?: (row: R) => void
  expandedIndice: number | null
  setExpandedIndice: (indice: number | null) => void
  mobileRowDetails: React.ReactNode
  keyField: KeyFieldType
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
  collapsedContent?: (row: R) => React.ReactNode | undefined
}

export const MobileRow = <R,>({
  columns,
  rowIndex,
  row,
  onRowClick,
  expandedIndice,
  setExpandedIndice,
  mobileRowDetails,
  keyField,
  collapsedContent,
  collapsed,
  setCollapsed
}: MobileRowProps<R>) => {
  const {classes} = useTableStyles()

  const handleMobileExpandedRow = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
    row: R
  ) => {
    event.stopPropagation()
    onRowClick && onRowClick(row)
    if (expandedIndice === index) {
      setExpandedIndice(null)
    } else {
      setExpandedIndice(index)
    }
  }

  const headerOrRow = (column: TableColumnType, columnIndex: number) =>
    column.mobileRowHeader ? (
      <Box
        key={`mobile-row-header-${column.field}-${rowIndex}`}
        data-test-id={`mobile-row-header-${column.field}-${rowIndex}`}
        className={classes.mobileHeadRow}
        onClick={(event) => handleMobileExpandedRow(event, rowIndex, row)}
      >
        {column.renderCell ? column.renderCell(row) : row[column.field]}
        {onRowClick ? (
          expandedIndice === rowIndex ? (
            <KeyboardArrowUpIcon style={{color: '#016AD4'}} />
          ) : (
            <KeyboardArrowDownIcon style={{color: '#016AD4'}} />
          )
        ) : null}
      </Box>
    ) : (
      <Grid
        container
        key={`mobile-row-${column.field}-${rowIndex}`}
        data-test-id={`mobile-row-${column.field}-${rowIndex}`}
        style={{
          borderBottom: columnIndex + 1 === columns.length ? 'none' : '1px solid #EAEDF0',
          padding: '6px 0'
        }}
      >
        <Grid item xs={5}>
          <Box style={{display: 'flex', alignItems: 'center'}}>
            {collapsedContent?.(row) && column.mobileCollapsed && (
              <IconButton
                onClick={() => setCollapsed(!collapsed)}
                style={{border: 'none', outline: 'none', boxShadow: 'none'}}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform 0.3s ease-in-out'
                  }}
                />
              </IconButton>
            )}
            {column.headerName && (
              <Typography
                style={{fontWeight: 600, fontSize: '14px', color: '#54708C'}}
              >{`${column.headerName}: `}</Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={column.headerName ? 7 : 12}>
          {column.renderCell ? column.renderCell(row) : row[column.field]}
        </Grid>
        <Grid item xs={12}>
          {collapsedContent?.(row) && column.mobileCollapsed && (
            <Collapse in={collapsed} style={{display: 'block'}} mountOnEnter={true}>
              {collapsedContent(row)}
            </Collapse>
          )}
        </Grid>
      </Grid>
    )

  return (
    <TableCell
      key={rowIndex}
      component="div"
      data-test-id={`mobile-row-${rowIndex + 1}-${row[keyField]}`}
      style={{
        padding: '0 8px',
        borderBottom: 'none'
      }}
    >
      {columns.map((column, index) => headerOrRow(column, index))}
      {expandedIndice === rowIndex && (
        <Collapse
          component="div"
          data-test-id="responsive-table-row-expand"
          style={{marginBottom: '10px'}}
          in={expandedIndice === rowIndex}
          mountOnEnter
          unmountOnExit
        >
          {mobileRowDetails}
        </Collapse>
      )}
    </TableCell>
  )
}

import {Box, BoxProps} from '@mui/material'
import React from 'react'

export const MenuLinksBox: React.FC<BoxProps> = (props) => (
  <Box
    bgcolor="background.paper"
    color="text.primary"
    borderRadius={1}
    mb={{xs: 1, sm: 1, md: 0}}
    {...props}
  />
)

import {Box, IconButton, Modal as MaterialModal, ModalProps, Paper} from '@material-ui/core'
import {makeStyles, Theme} from '@material-ui/core/styles'
import {Close} from '@material-ui/icons'
import React, {FC} from 'react'

import {LayoutConstants} from '../../common/constants'

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 16px)',
      height: 'calc(100% - 16px)',
      margin: `${theme.spacing(2)}px`
    },
    display: 'flex',
    width: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    height: 'calc(100% - 64px)',
    flexDirection: 'column',
    margin: `${theme.spacing(8)}px`,
    position: 'relative',
    overflowY: 'auto',
    '&:focus': {
      outline: 'none'
    }
  },
  paperFitContent: {
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
    flexDirection: 'column',
    // margin: `${theme.spacing(8)}px`,
    position: 'relative',
    overflowY: 'auto',
    '&:focus': {
      outline: 'none'
    }
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    overflowY: 'auto'
  }
}))

interface CloseButtonProps {
  onClose: () => void
  disabled?: boolean
}

const useButtonStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    marginRight: theme.spacing(2)
  }
}))

const CloseButton: React.FC<CloseButtonProps> = ({onClose, disabled = false}) => {
  const classes = useButtonStyles()
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" height={80}>
      <div className={classes.closeButton}>
        <IconButton
          onClick={() => onClose()}
          style={{border: 'none', boxShadow: 'none'}}
          data-test-id="modal-close-button"
          disabled={disabled}
        >
          <Close />
        </IconButton>
      </div>
    </Box>
  )
}

interface ModalCustomProps {
  open: boolean
  onClose: () => void
  haveCloseButton?: boolean
  isDisableClose?: boolean
  fitContent?: boolean
}

export const Modal: FC<ModalCustomProps & ModalProps> = ({
  open,
  children,
  onClose,
  haveCloseButton = true,
  isDisableClose = false,
  fitContent = false
}) => {
  const classes = useStyles()
  return (
    <MaterialModal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <Paper className={fitContent ? classes.paperFitContent : classes.paper} elevation={4}>
        {haveCloseButton ? (
          <CloseButton onClose={() => onClose()} disabled={isDisableClose} />
        ) : null}
        <Box className={classes.content}>{children}</Box>
      </Paper>
    </MaterialModal>
  )
}

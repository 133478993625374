import {makeStyles, Theme} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

import Typography from './Typography'

const useSectionHeaderStyles = makeStyles((theme: Theme) => ({
  second: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-end'
  },
  fullWidth: {
    width: '100%'
  },
  sectionHeader: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  title: {
    paddingBottom: theme.spacing(0.25),
    borderBottom: `1px solid ${theme.palette.secondary.dark}`
  },
  first: {
    marginTop: theme.spacing(0)
  }
}))

interface SectionHeaderProps {
  title: string
  caption?: string
  first?: boolean
  second?: string | JSX.Element
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({title, caption, first, second}) => {
  const classes = useSectionHeaderStyles()
  return (
    <div
      className={classNames(classes.sectionHeader, {
        [classes.first]: first,
        [classes.fullWidth]: !!second
      })}
    >
      <div className={classNames(classes.title, {[classes.second]: !!second})}>
        <Typography variant="h3">{title}</Typography>
        {second ? second : null}
      </div>
      {caption ? (
        <Typography variant="caption" color="secondary">
          {caption}
        </Typography>
      ) : null}
    </div>
  )
}

import {Checkbox, TableCell, TableHead, TableRow} from '@mui/material'
import classNames from 'classnames'
import React, {RefObject, useEffect, useState} from 'react'

import {useElementSize} from '../../../Shell'
import {TableFilterType} from '../Filter/TableFilter.types'
import {useTableStyles} from '../styles'
import {GridRowsProp, GridValueRowModel, TableColumnType} from '../types'

import {ResponsiveTableHeadCell} from './ResponsiveTableHeadCell'

type ResponsiveTableHeadProps<R extends GridValueRowModel> = {
  selectionEnabled: boolean
  allRowsSelected: boolean
  isAllSelectedDisabled: boolean
  columns: TableColumnType[]
  rows: GridRowsProp<R>
  deselectAllRows: () => void
  setAllRowsSelected: (selected: boolean) => void
  rowsSelected: Set<string>
  filteredColumns: TableColumnType[]
  loading: boolean
  onSort: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, field: string) => void
  setScrollLabel: (label?: string) => void
  handlePinColumn: (
    column: TableColumnType,
    direction?: 'left' | 'right',
    unpinAll?: boolean
  ) => void
  tableCellRefs: RefObject<{ref: HTMLDivElement | null; field: string}[]>
  scrollBoundary: 'start' | 'end' | 'between' | undefined
  enablePinning: boolean
  isMobile: boolean
  isCollapsedContent: boolean
  filterListInputValues?: TableFilterType[]
  setFilterListInputValues?: (filterListInputValues: TableFilterType[]) => void
  shouldDisplayScrollButtons?: boolean
  scrollLabel?: string
  columnHeaderHeight?: number
  rowHeight?: number
  sortedBy?: string
  sortingOrder?: 'asc' | 'desc'
  columnsWidth?: {field: string; width: number}[]
}
export const ResponsiveTableHead = <R extends GridValueRowModel>({
  allRowsSelected,
  isAllSelectedDisabled,
  selectionEnabled,
  columns,
  rows,
  deselectAllRows,
  setAllRowsSelected,
  rowsSelected,
  filteredColumns,
  columnHeaderHeight,
  rowHeight,
  onSort,
  sortingOrder,
  sortedBy,
  loading,
  scrollLabel,
  setScrollLabel,
  handlePinColumn,
  columnsWidth,
  tableCellRefs,
  scrollBoundary,
  shouldDisplayScrollButtons,
  filterListInputValues,
  setFilterListInputValues,
  enablePinning,
  isCollapsedContent,
  isMobile
}: ResponsiveTableHeadProps<R>) => {
  const {classes} = useTableStyles()
  const lastLeftChild = columns?.filter((val) => val.pinDirection === 'left').pop()
  const firstRightChild = columns?.filter((val) => val.pinDirection === 'right').shift()
  const [isContentWrapped, setIsContentWrapped] = useState(false)
  const [ref, {height}] = useElementSize()
  const defaultHeightValue = 40
  const isHeaderGreaterThanDefault = columnHeaderHeight && columnHeaderHeight > defaultHeightValue
  const isRowGreaterThanDefault = rowHeight && rowHeight > defaultHeightValue

  useEffect(() => {
    if (
      height >
      (isHeaderGreaterThanDefault
        ? columnHeaderHeight
        : isRowGreaterThanDefault
        ? rowHeight
        : defaultHeightValue)
    ) {
      setIsContentWrapped(true)
    }
  }, [height])

  return (
    <TableHead component="div">
      <TableRow
        ref={ref}
        component="div"
        style={{
          position: 'relative',
          height: columnHeaderHeight !== undefined ? columnHeaderHeight : '40px'
        }}
      >
        {selectionEnabled ? (
          <TableCell
            component="div"
            data-test-id="table-select-all"
            className={classNames(classes.headCell)}
            align="center"
            style={{
              width: '20px',
              padding: '6px 16px',
              verticalAlign: isContentWrapped ? 'top' : 'inherit'
            }}
          >
            <Checkbox
              data-test-id="header-checkbox"
              checked={allRowsSelected}
              disabled={isAllSelectedDisabled}
              size="small"
              color="secondary"
              onChange={() => {
                allRowsSelected ? deselectAllRows() : setAllRowsSelected(true)
              }}
              indeterminate={Boolean(rows?.length && rowsSelected.size > 0 && !allRowsSelected)}
              style={{padding: 0}}
            />
          </TableCell>
        ) : null}

        {columns && isCollapsedContent && !isMobile && (
          <TableCell
            component="div"
            width={20}
            className={classes.headCell}
            style={{
              position: columns?.[0].pinDirection ? 'sticky' : undefined,
              left: shouldDisplayScrollButtons && scrollBoundary !== 'start' ? 34 : 0,
              zIndex: columns?.[0].pinDirection ? 3 : undefined,
              ...(columnHeaderHeight || rowHeight ? {height: columnHeaderHeight || rowHeight} : {})
            }}
          />
        )}

        {columns
          .filter(
            (column) =>
              column.noColumnSelection ||
              filteredColumns.map((column) => column.field).includes(column.field)
          )
          .map((column) => {
            return column.hide ? null : (
              <ResponsiveTableHeadCell
                key={column.field}
                column={column}
                columns={columns}
                handlePinColumn={handlePinColumn}
                setScrollLabel={setScrollLabel}
                loading={loading}
                onSort={onSort}
                sortedBy={sortedBy}
                scrollLabel={scrollLabel}
                sortingOrder={sortingOrder}
                rowHeight={rowHeight}
                columnHeaderHeight={columnHeaderHeight}
                tableCellRefs={tableCellRefs}
                columnsWidth={columnsWidth}
                scrollBoundary={scrollBoundary}
                shouldDisplayScrollButtons={shouldDisplayScrollButtons}
                shouldDisplayLeftBoxShadow={lastLeftChild?.field === column.field}
                shouldDisplayRightBoxShadow={firstRightChild?.field === column.field}
                filterListInputValues={filterListInputValues}
                setFilterListInputValues={setFilterListInputValues}
                enablePinning={enablePinning}
                isCollapsedContent={isCollapsedContent}
                isContentWrapped={isContentWrapped}
              />
            )
          })}
      </TableRow>
    </TableHead>
  )
}

import {MenuItem} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

export default withStyles(({palette, spacing}) => ({
  root: {
    color: palette.common.white,
    padding: spacing(1.5),
    '&:hover': {
      backgroundColor: 'rgba(231, 231, 231, 0.1)'
    }
  },
  selected: {
    backgroundColor: 'rgba(231, 231, 231, 0.2) !important'
  }
}))(MenuItem)

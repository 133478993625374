import {svgFlagSources} from '@hconnect/common/assets/flags'
import {clamp, toUpper} from 'lodash'

export const calcRelativeValueInPercent = (value: number, min: number, max: number): number => {
  if (min > max) {
    throw new Error(`max ${max} must be greater than min ${min}`)
  }
  return clamp((100 * (value - min)) / (max - min), 0, 100)
}

// This function is helpful for creating a valid data-test-id from an arbitrary string
export const replaceNonWordCharacters = (name: string, replaceValue = '-'): string => {
  return name.replace(/\W/g, replaceValue)
}

export const flagPathByCountryCode = (countryCode: string) => {
  return svgFlagSources[toUpper(countryCode)]
}

import {Box} from '@material-ui/core'
import React from 'react'

import {flagPathByCountryCode} from '../../../common'

type Props = {
  country?: string
  rounded?: boolean
  onClick?: (event: any) => void
}

export const Flag: React.FC<Props> = ({country, rounded, onClick}) => {
  if (country && !flagPathByCountryCode(country)) {
    return null
  }
  return (
    <Box display="inline-flex">
      <img
        src={flagPathByCountryCode(country || '')}
        style={rounded ? {clipPath: 'circle(42% at center)'} : {}}
        onClick={onClick}
        alt={country}
      />
    </Box>
  )
}

import {useEffect} from 'react'

import {TableColumnType} from '../types'

export const useStoreColumns = (
  filteredColumns: TableColumnType[],
  columns: TableColumnType[],
  storeFiltersOptions?: {
    tableName: string
    userId: string
  }
) => {
  const storedColumns = localStorage.getItem(
    `activeColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
  )
  useEffect(() => {
    const selectableColumns = columns.filter((column) => !column.noColumnSelection)
    const parsedStoredColumns = storedColumns && (JSON.parse(storedColumns) as string[])
    storeFiltersOptions &&
      filteredColumns.filter((filCol) => !filCol.uncheckedByDefault).length !==
        selectableColumns.length &&
      localStorage.setItem(
        `activeColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`,
        JSON.stringify(filteredColumns.map((column) => column.field))
      )

    const uncheckedFilteredItems = filteredColumns.filter(
      (filCol) =>
        filCol.uncheckedByDefault && columns.map((col) => col.field).includes(filCol.field)
    )

    parsedStoredColumns &&
      selectableColumns.length ===
        filteredColumns.filter((filCol) => !filCol.uncheckedByDefault).length &&
      uncheckedFilteredItems.length === 0 &&
      localStorage.removeItem(
        `activeColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredColumns])

  return {storedColumns}
}

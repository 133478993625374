import {Box, BoxProps, makeStyles} from '@material-ui/core'
import cn from 'classnames'
import React from 'react'

import BoxedContainer from './BoxedContainer'
import PageTitle from './PageTitle'
import Typography from './Typography'

const useStyle = makeStyles((theme) => ({
  pageContainer: {
    flexGrow: 1
  },
  default: {},
  sheet: {
    background: theme.palette.common.white,
    color: theme.palette.text.primary
  },
  subPage: {
    padding: 0
  },
  pageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  },
  headerActions: {
    minHeight: 46
  },
  titleAndSubTitle: {
    marginBottom: theme.spacing(2)
  },
  titleAndSubTitleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  beforeHeaderElementWrapper: {
    display: 'flex',
    marginRight: theme.spacing(1)
  },
  beforeHeaderElementWrapperSpacing: {
    marginBottom: theme.spacing(2)
  },
  withDetails: {
    height: '100%',
    paddingLeft: theme.spacing(6.5),
    paddingRight: theme.spacing(6.5)
  }
}))

export interface PageProps extends Pick<BoxProps, 'px' | 'py'> {
  headerActionContent?: React.ReactNode
  beforeHeaderContent?: React.ReactNode
  title?: React.ReactNode
  aboveTitle?: React.ReactNode
  subTitle?: React.ReactNode
  children?: React.ReactNode
  variant?: 'default' | 'sheet' | 'subPage' | 'withDetails'
  boxed?: boolean
  className?: string
  classNameHeader?: string
  classNameBody?: string
  classNameActionContent?: string
  'data-test-id'?: string
}

export const Page: React.FC<PageProps> = ({
  headerActionContent,
  beforeHeaderContent,
  title,
  aboveTitle,
  subTitle,
  children,
  variant = 'default',
  boxed = true,
  'data-test-id': dataTestId,
  classNameHeader,
  classNameBody,
  className,
  classNameActionContent,
  py = 6,
  px = 2
}) => {
  const classes = useStyle()

  const pageTitle = <PageTitle data-test-id={dataTestId}>{title}</PageTitle>

  const secondaryTitle = (secondaryTitle?: React.ReactNode | string) => (
    <Typography variant="h4" customColor="textSecondarySoft">
      {secondaryTitle}
    </Typography>
  )

  const pageTitleAndSubTitle = (subTitle: React.ReactNode | string) => (
    <div className={classes.titleAndSubTitle}>
      {pageTitle}
      {secondaryTitle(subTitle)}
    </div>
  )

  const content = (
    <>
      {aboveTitle !== undefined && secondaryTitle(aboveTitle)}
      {(title || headerActionContent) && (
        <div className={cn(classes.pageHeader, classNameHeader)}>
          {
            <div className={classes.titleAndSubTitleWrapper}>
              {beforeHeaderContent && (
                <div
                  className={cn(
                    classes.beforeHeaderElementWrapper,
                    subTitle && classes.beforeHeaderElementWrapperSpacing
                  )}
                >
                  {beforeHeaderContent}
                </div>
              )}
              {subTitle ? pageTitleAndSubTitle(subTitle) : pageTitle}
            </div>
          }
          {headerActionContent && (
            <div className={cn(classes.headerActions, classNameActionContent)}>
              {headerActionContent}
            </div>
          )}
        </div>
      )}
      <div className={cn(classNameBody)}>{children}</div>
    </>
  )

  return (
    <Box px={px} py={py} flexGrow={1} className={cn(classes[variant], className)}>
      {boxed ? <BoxedContainer>{content}</BoxedContainer> : content}
    </Box>
  )
}

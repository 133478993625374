import {KeyboardArrowDown, Search} from '@mui/icons-material'
import {
  alpha,
  styled,
  Typography,
  TypographyProps,
  Popover as PopoverBase,
  MenuList as MenuListBase,
  css,
  InputAdornment,
  FormControl,
  InputProps,
  InputLabel,
  Input
} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {TitleButton} from '../button'

const getGrey =
  (val: string) =>
  ({theme}) =>
    theme.palette.grey[val]

export const MainButton = styled(TitleButton)`
  width: 100%;
  align-items: start;
  background-color: ${getGrey('old50')};
  border: 1px solid ${getGrey('old100')};
  &:hover {
    color: ${getGrey('old100')};
    background-color: ${getGrey('old100')};
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

export const Caption = styled(Typography)<TypographyProps & {dark: boolean}>`
  color: ${({theme, dark}) =>
    dark ? theme.palette.text.secondary : theme.palette.text.primarySoft};
  text-align: left;
`

export const CroppedCaption = styled(Typography)<TypographyProps & {dark: boolean}>`
  color: ${({theme, dark}) =>
    dark ? theme.palette.text.secondary : theme.palette.text.primarySoft};
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SelectedOptionTypography = styled(Typography)<TypographyProps & {dark: boolean}>`
  color: ${({theme, dark}) => (dark ? theme.palette.text.secondary : theme.palette.secondary.dark)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
`

export const TitleBox = styled('div')`
  width: 100%;
  padding-top: ${({theme}) => theme.spacing(0.2)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
`

export const DownIcon = styled(KeyboardArrowDown)`
  margin-left: ${({theme}) => theme.spacing(2)};
  color: ${({theme}) => alpha(theme.palette.secondary.dark, 0.96)};
`

const MAX_WIDTH = 35 // (35 x 8px = 280px)
// const MAX_HEIGHT = 56.75 // (56.75 x 8px = 454px)

export const Popover = styled(PopoverBase)(
  ({theme}) => css`
    color: ${theme.palette.common.white};
    margin-top: ${theme.spacing(2)};
    & .MuiList-root > * {
      white-space: normal;
    }
    & .Mui-selected.Mui-selected {
      background-color: ${theme.palette.grey[50]};
      & span {
        border: none;
      }
      & div {
        border: none;
      }
      &:hover {
        background-color: ${theme.palette.grey[100]};
      }
    }
  `
)

export interface MenuListProps {
  maxWidth?: number
}

export const MenuList = styled(MenuListBase)<MenuListProps>`
  max-width: ${({theme, maxWidth}) => theme.spacing(maxWidth ? maxWidth : MAX_WIDTH)};
  max-height: ${({theme}) => theme.spacing(MAX_WIDTH)};
  overflow-y: auto;
`

export const SearchFieldWrapper = styled('div')`
  padding: ${({theme}) => theme.spacing(0.5)} ${({theme}) => theme.spacing(2)};
`

type SearchFieldProps = InputProps & {label: string; key: string; customClassName?: string}

const StyledAdornment = styled(InputAdornment)`
  & > * {
    background: transparent;
  }
`
export const SearchField: React.FC<SearchFieldProps> = ({
  label,
  key,
  customClassName,
  ...props
}) => {
  const {t} = useTranslation()
  return (
    <SearchFieldWrapper>
      <FormControl variant="standard" focused className={customClassName}>
        <InputLabel htmlFor={key}>{t('dropdown.searchList')}</InputLabel>
        <Input
          {...props}
          id={key}
          data-test-id="input-search"
          aria-label={`${label} lookup input`}
          startAdornment={
            <StyledAdornment position="start" aria-label={`${label} search icon`}>
              <Search style={{color: 'inherit'}} />
            </StyledAdornment>
          }
        />
      </FormControl>
    </SearchFieldWrapper>
  )
}

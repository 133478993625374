import {appUrl, hproduce} from '@hconnect/common/hproduce/config'
import {AppName, PlantId, WhiteList} from '@hconnect/common/hproduce/types'
import {makeStyles, Popover, Link, Typography, Box} from '@material-ui/core'
import {Apps as AppsIcon} from '@material-ui/icons'
import classNames from 'classnames'
import React, {useState, useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {useMobileBreakPoint} from '../Hooks'
import Divider from '../Molecules/Divider'

import {HProduceDashboardLink} from './HProduceDashboardLink'
import HproduceIconButton from './HproduceIconButton'
import {MenuLinksBox} from './Nav'
import {PlantSelect} from './PlantSelect'
import {ProductSelect} from './ProductSelect'

const useStyles = makeStyles((theme) => ({
  activeMenuButton: {
    backgroundColor: theme.palette.grey[100],
    boxShadow: 'none'
  },
  popoverPaper: {
    marginTop: theme.spacing(2.5),
    minWidth: '300px',
    boxShadow: theme.shadows[10],
    borderRadius: 8
  },
  divider: {
    margin: theme.spacing(0, 2)
  },
  productSelectWrapper: {
    marginBottom: 0
  },
  moreProducts: {
    fontWeight: 600,
    fontSize: 12
  }
}))

type AppSelectProps = {
  activeAppName?: AppName | 'HProduce' | 'HConnect'
  activePlantId?: PlantId | undefined
  'data-test-id'?: string
  whiteList: WhiteList
}

export const AppSelect: React.FC<AppSelectProps> = ({
  activeAppName,
  activePlantId,
  'data-test-id': dataTestId = 'select',
  whiteList
}) => {
  const {t} = useTranslation()
  const isMobile = useMobileBreakPoint()
  const {optionsPerPlant} = whiteList

  const allPlants = useMemo(
    () =>
      Object.keys(optionsPerPlant).map((plantId) => ({
        plantId,
        plantName: optionsPerPlant[plantId][0]?.plantName
      })),
    [optionsPerPlant]
  )

  const {divider, moreProducts, productSelectWrapper} = useStyles()

  const [plantId, setPlantId] = useState(() => {
    return (
      activePlantId ||
      (activeAppName &&
        activeAppName !== 'HProduce' &&
        whiteList.optionsPerApp[activeAppName]?.[0].plantId) ||
      allPlants[0].plantId
    )
  })

  return (
    <>
      {allPlants.length > 1 && (
        // show the app select only in case there are multiple plants available
        <MenuLinksBox>
          <PlantSelect
            activePlantId={plantId}
            data-test-id={`${dataTestId}-plant`}
            onChange={setPlantId}
            plants={allPlants}
          />
        </MenuLinksBox>
      )}
      <MenuLinksBox className={productSelectWrapper}>
        {!isMobile && (
          <>
            <HProduceDashboardLink activePlantId={plantId} />
            <Divider orientation="horizontal" color="soft" className={divider} />
          </>
        )}
        <ProductSelect
          data-test-id={`${dataTestId}-product`}
          activeAppName={activeAppName}
          activePlantId={activePlantId}
          options={optionsPerPlant[plantId] ?? []}
        />
        <Divider orientation="horizontal" color="soft" className={divider} />
        <Box p={2}>
          <Link underline={'none'} href={`${appUrl(hproduce.url)}/goDigital`}>
            <Typography variant={'subtitle2'} align={'center'} className={moreProducts}>
              {t('burgerMenu.more', 'More HProduce Products')}
            </Typography>
          </Link>
        </Box>
      </MenuLinksBox>
    </>
  )
}

type AppSelectPopUpProps = AppSelectProps & {
  'data-test-id'?: string
  iconButtonClassName?: string
}

export const AppSelectPopUp: React.FC<AppSelectPopUpProps> = ({
  'data-test-id': dataTestId = 'grid-menu-icon',
  iconButtonClassName,
  ...rest
}) => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const classes = useStyles()

  return (
    <>
      <HproduceIconButton
        dataTestId={dataTestId}
        onClick={() => setOpen(!isOpen)}
        className={classNames(iconButtonClassName, {[classes.activeMenuButton]: isOpen})}
        size="medium"
        aria-label="toggle apps"
        focusRipple={isOpen}
      >
        <AppsIcon style={{fontSize: '16px'}} />
      </HproduceIconButton>
      <Popover
        classes={{paper: classes.popoverPaper}}
        data-test-id={`${dataTestId}-popover`}
        onClose={() => setOpen(false)}
        open={isOpen}
        anchorReference="anchorPosition"
        anchorPosition={{left: 32, top: 48}}
      >
        <AppSelect {...rest} data-test-id={`${dataTestId}-select`} />
      </Popover>
    </>
  )
}

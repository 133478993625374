import {Tabs as AbstractTabs, TabsProps as AbstractTabsProps, makeStyles} from '@material-ui/core'
import React from 'react'

import {HCThemeType} from '../../HCTheme.types'

const useStyle = makeStyles((theme: HCThemeType) => ({
  primary: {},
  secondary: {
    backgroundColor: 'transparent',
    '& .Mui-selected': {
      color: theme.palette.text.link,
      backgroundColor: theme.palette.background.default
    }
  }
}))

export type TabColor = 'primary' | 'secondary'

// TODO this omit should be changed and the type for onchange should not be overridden. This would require changing the types of many dependants so for now it's ok to extend the props
export interface TabsProps extends Omit<AbstractTabsProps, 'onChange'> {
  color?: TabColor
  indicatorColor?: 'secondary' | 'primary'
  textColor?: 'secondary' | 'primary' | 'inherit'
  value?: any
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void
}

export const Tabs: React.FC<TabsProps> = ({children = undefined, color = 'primary', ...props}) => {
  const classes = useStyle()
  return (
    <AbstractTabs className={classes[color]} {...props}>
      {children}
    </AbstractTabs>
  )
}

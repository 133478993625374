import React, {ForwardedRef, forwardRef} from 'react'

import Typography from '../Typography'

interface Props {
  children: React.ReactNode
}

export const SecondaryCell = forwardRef((props: Props, ref: ForwardedRef<HTMLSpanElement>) => (
  <Typography ref={ref} variant="body2" {...props} />
))

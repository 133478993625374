import {makeStyles} from '@material-ui/core'
export const useNavLinkStyles = makeStyles((theme) => ({
  link: {
    ...theme.typography.h4,
    color: theme.palette.text.primary,
    lineHeight: '22px',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  linkWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  linkWrapperCentered: {
    display: 'flex'
  },
  verticalLink: {
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  activeLink: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
    borderBottomWidth: 2,
    borderBottomStyle: 'solid'
  },
  noPointerEvents: {
    pointerEvents: 'none'
  },
  pointerEvents: {
    pointerEvents: 'auto'
  },
  disabledLink: {
    color: theme.palette.secondary.main,
    borderBottomColor: 'none',
    pointerEvents: 'none',
    padding: '12px 0'
  },
  divider: {
    margin: theme.spacing(0, 2)
  },
  subVerticalLink: {
    marginLeft: 16,
    paddingLeft: 16
  },
  subDivider: {
    margin: theme.spacing(0, 2, 0, 4)
  }
}))

export const useStyles = makeStyles((theme) => ({
  activeMenuButton: {
    backgroundColor: theme.palette.grey[100],
    boxShadow: 'none'
  },
  overlayVisibility: {
    [theme.breakpoints.up('md')]: {
      opacity: 0,
      pointerEvents: 'none'
    }
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.common.white
    },
    '& .MuiTabs-flexContainer': {
      gap: 48
    }
  },
  tab: {
    textTransform: 'capitalize',
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 'unset'
  }
}))

import {Box, BoxProps, makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {ReactComponent as Logo} from '../../common/assets/hconnect.svg'
import {HCThemeType} from '../HCTheme.types'

import Typography from './Typography'

export type HConnectLogoColor = 'black' | 'white'

export interface HConnectLogoProps {
  logoColor?: HConnectLogoColor
}

const useStyle = makeStyles((theme: HCThemeType) => ({
  logo: {
    margin: theme.spacing(0, 1),
    height: '32px',
    width: 'auto'
  },
  black: {
    fill: '#004E2B'
  },
  white: {
    fill: theme.palette.common.white
  }
}))

const HConnectLogo: React.FC<HConnectLogoProps & BoxProps> = ({logoColor = 'black', ...props}) => {
  const {t} = useTranslation()
  const classes = useStyle()
  const className = classNames(classes.logo, classes[logoColor])
  return (
    <Box display="flex" alignItems="center" {...props}>
      <Typography variant="caption" color="secondary" noWrap>
        {t('footer.poweredBy')}
      </Typography>
      <Logo className={className} aria-label="HConnect" />
    </Box>
  )
}

export default HConnectLogo

import {IconButton, TableFooter, TablePagination, TableRow} from '@material-ui/core'
import {KeyboardArrowLeft, KeyboardArrowRight} from '@material-ui/icons'
import React from 'react'

import {useStyle} from './DataTable.styles'

export type PaginationOptions = {
  onPaginationChange: (pageNumber: number, itemsPerPage: number) => void
  totalDataLength: number
  page: number
  rowsPerPage: number
}

interface DataTablePaginationProps {
  paginationOptions: PaginationOptions
}

export const DataTablePagination: React.FC<DataTablePaginationProps> = ({paginationOptions}) => {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (paginationOptions.onPaginationChange) {
      paginationOptions.onPaginationChange(newPage, paginationOptions.rowsPerPage)
    }
  }

  const handleChangeRowsPerPage = (event: {target: {value: string}}) => {
    if (paginationOptions.onPaginationChange) {
      paginationOptions.onPaginationChange(0, parseInt(event.target.value, 10))
    }
  }

  const classes = useStyle()

  return (
    <TableFooter component="div" className={classes.tableFooter}>
      <TableRow component="div">
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          colSpan={3}
          count={paginationOptions.totalDataLength}
          rowsPerPage={paginationOptions.rowsPerPage}
          page={paginationOptions.page}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  )
}

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = useStyle()
  const {count, page, rowsPerPage, onPageChange} = props

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  return (
    <div className={classes.paginationCustom}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
    </div>
  )
}

import {allApps, Permission, WhiteList} from '@hconnect/common/hproduce'
import {AppConfig, AppName, Env} from '@hconnect/common/hproduce/types'
import {addToWhiteList, whiteListByPermission} from '@hconnect/common/hproduce/whiteListCheck'
import {Box, makeStyles} from '@material-ui/core'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link as LinkComponent} from 'react-router-dom'

import {AppLogo} from '../../../common/assets/HProduceApps'
import {useMobileBreakPoint} from '../../Hooks/useBreakPoints'
import Typography from '../../Molecules/Typography'
import {AppSelectPopUp} from '../AppSelect'
import HProduceAccountDropdown from '../HProduceAccountDropdown'
import {Plant} from '../PlantSelect'
import {User} from '../types'

import {BurgerMenu} from './BurgerMenu'
import {splitArray} from './common'
import {NavMenu} from './components'
import {useElementSize, useIsomorphicLayoutEffect} from './hooks'
import {NavItem} from './types'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  icon: {
    marginRight: 8
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2)
    }
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto'
    }
  },
  app: {
    fontWeight: 600,
    lineHeight: '20px'
  },
  plant: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.text['primarySoft'],
    fontWeight: 600,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap'
  },
  appContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      justifyContent: 'flex-start'
    }
  },
  desktopLinksContainer: {
    display: 'flex'
  }
}))

export type CommonHeaderProps = {
  appName: AppName | 'HProduce' | 'HConnect'
  plant?: Plant
  navItems?: NavItem[]
  navItemSelected?: () => void
  keepPointerEvents?: boolean
  supportedLanguages?: string[]
  user?: User
  analytics?: (route: string) => void
  onNavItemHover?: (route: string) => void
  parentWidth?: number
  closeOverlays?: () => void
  enableMyTasks?: boolean
} & (
  | {
      disableAppSelect: true
    }
  | {
      permissions: Permission[]
      env: Env
    }
  | {
      whiteList: WhiteList
      env: Env
    }
) & {
    // TODO tell typescript that you need booth or none, currently a mix is acceptet but not use full
    onLogout?: () => void
    user?: User
  }

function getWhiteListFromProps(props: CommonHeaderProps) {
  let whiteList: WhiteList | undefined = undefined
  if ('disableAppSelect' in props) return

  if ('whiteList' in props && props.whiteList) {
    whiteList = props.whiteList
  } else if ('permissions' in props && props.permissions) {
    whiteList = whiteListByPermission(props.env, props.permissions)
  }

  // this is a temporary safety net until the permission check is bullet-proved,
  // it will make sure that at least app the user is currently in, is available in the list

  const {plant, appName, env} = props
  const plantId = plant?.plantId
  const appConfig: AppConfig = allApps[appName]

  if (plantId && whiteList && !whiteList.optionsMap.has(appName + plantId) && appConfig) {
    addToWhiteList(env, whiteList, appConfig, plantId)
  }

  return whiteList
}

const MAX_WIDTH_OF_NAV_ITEM = 250

export const CommonHeader: React.FC<CommonHeaderProps> = (props) => {
  const {
    analytics,
    appName,
    navItems,
    navItemSelected,
    plant,
    parentWidth,
    closeOverlays,
    onNavItemHover,
    enableMyTasks,
    keepPointerEvents
  } = props
  const [navRef, {width: navWidth}] = useElementSize()
  const {
    t,
    i18n: {changeLanguage, language}
  } = useTranslation()
  const classes = useStyles()

  const [items, setItems] = React.useState<NavItem[] | undefined>(navItems)

  const handleSelect = (item?: NavItem) => {
    navItemSelected && navItemSelected()
    analytics && item && analytics(item.url)
  }

  const localesList = props.supportedLanguages
    ? props.supportedLanguages.map((code) => ({
        code,
        name: t(`locales.${code}`)
      }))
    : []
  const isMobile = useMobileBreakPoint()

  const whiteList = useMemo(
    () => getWhiteListFromProps(props),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props['disableAppSelect'], props['whiteList'], props['permissions']]
  )

  // disable the app select only when prop is sent or user doesn't have permissions to see any app
  const disableAppSelect = !whiteList

  useIsomorphicLayoutEffect(() => {
    const spaceForItems = parentWidth ? Math.trunc(parentWidth / MAX_WIDTH_OF_NAV_ITEM) : 0
    const items = splitArray<NavItem>(
      navItems || [],
      spaceForItems && navItems && navItems.length && spaceForItems < navItems.length
        ? navItems.length - spaceForItems + 1
        : 0,
      t('more', 'More')
    )
    setItems(items)
  }, [navWidth, parentWidth, navItems])

  return (
    <>
      <Box display="flex" width="100%" data-test-id="common-header">
        <nav ref={navRef} className={classes.nav}>
          {isMobile ? (
            <BurgerMenu
              iconButtonClassName={classes.menuButton}
              disableAppSelect={disableAppSelect}
              navItems={navItems}
              activeAppName={appName}
              activePlantId={plant?.plantId}
              whiteList={whiteList}
              analytics={analytics}
              closeOverlays={closeOverlays}
              navItemSelected={handleSelect}
            />
          ) : (
            !disableAppSelect && (
              <AppSelectPopUp
                iconButtonClassName={classes.menuButton}
                activeAppName={appName}
                activePlantId={plant?.plantId}
                whiteList={whiteList}
              />
            )
          )}

          {appName !== 'HConnect' ? (
            <Box className={classes.appContainer}>
              <LinkComponent className={classes.logo} to="/" data-test-id="common-header-logo">
                {!['HProduce', 'HConnect'].includes(appName) && (
                  <AppLogo name={appName} className={classes.icon} />
                )}
                <div>
                  <Typography variant="h4" color="textPrimary" className={classes.app}>
                    {appName}
                  </Typography>
                  {plant?.plantName && (
                    <Typography variant="h4" color="secondary" className={classes.plant}>
                      {plant.plantName}
                    </Typography>
                  )}
                </div>
              </LinkComponent>
            </Box>
          ) : null}
          {!isMobile && (
            <Box className={classes.desktopLinksContainer} data-test-id="desktop-menu">
              <NavMenu
                items={items}
                isMobile={isMobile}
                handleSelect={handleSelect}
                onNavItemHover={onNavItemHover}
                keepPointerEvents={keepPointerEvents}
              />
            </Box>
          )}
        </nav>
      </Box>
      {props.user && props.onLogout && (
        <HProduceAccountDropdown
          actions={{
            logout: props.onLogout,
            selectLanguage: (lng: string) => void changeLanguage(lng)
          }}
          logoutButtonText={t('logout', 'Logout')}
          locales={{
            defaultLocale: language,
            locales: localesList,
            localeListLabel: t(`locales.${language}`)
          }}
          enableMyTasks={enableMyTasks}
          profile={{
            name: props.user.name ?? '',
            email: props.user.email ?? ''
          }}
        />
      )}
    </>
  )
}

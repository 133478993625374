import {makeStyles} from '@material-ui/core/styles'
import ExitToApp from '@material-ui/icons/ExitToApp'
import React from 'react'

import {Action} from './AccountDropdown.types'
import Typography from './Typography'

const useStyles = makeStyles((theme) => ({
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    background: theme.palette.common.white,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',

    '&:hover': {
      backgroundColor: '#F7F7F7',
      cursor: 'pointer'
    }
  }
}))

interface AccountDropdownLogoutButtonProps {
  actions: Action
  text: string
}

// https://material-ui.com/components/menus/#menus
// https://stackoverflow.com/questions/48157863/how-to-make-a-dropdown-menu-open-below-the-appbar-using-material-ui
const AccountDropdownLogoutButton: React.FC<AccountDropdownLogoutButtonProps> = ({
  text,
  actions
}) => {
  const classes = useStyles()

  const handleLogout = () => {
    actions.logout()
  }

  return (
    <div
      className={classes.logoutButton}
      onClick={handleLogout}
      data-test-id="account-menu-logout-button"
    >
      <ExitToApp color="secondary" />
      <Typography variant="body1" customColor="textPrimarySoft">
        {text}
      </Typography>
    </div>
  )
}

export default AccountDropdownLogoutButton

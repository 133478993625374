import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

import Typography from './Typography'

const useStyles = makeStyles((theme) => ({
  menuButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    background: theme.palette.common.white,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',

    '&:hover': {
      backgroundColor: '#F7F7F7',
      cursor: 'pointer'
    }
  }
}))

interface DropdownMenuButtonLinkProps {
  onClick: () => void
  text: string
  icon: React.ElementType
}

// https://material-ui.com/components/menus/#menus
// https://stackoverflow.com/questions/48157863/how-to-make-a-dropdown-menu-open-below-the-appbar-using-material-ui
const DropdownMenuButtonLink: React.FC<DropdownMenuButtonLinkProps> = ({text, onClick, icon}) => {
  const classes = useStyles()
  const Icon = icon
  return (
    <div className={classes.menuButton} onClick={onClick} data-test-id="account-menu-button">
      <Icon color="secondary" />
      <Typography variant="body1" customColor="textPrimarySoft">
        {text}
      </Typography>
    </div>
  )
}

export default DropdownMenuButtonLink

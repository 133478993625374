import {Box} from '@material-ui/core'
import {Close as CloseIcon, Menu as MenuIcon} from '@material-ui/icons'
import classNames from 'classnames'
import React, {useLayoutEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {Tab, Tabs} from '../../Molecules/Tabs'
import {AppSelect} from '../AppSelect'
import {HProduceDashboardLink} from '../HProduceDashboardLink'
import HproduceIconButton from '../HproduceIconButton'
import {Overlay} from '../Overlay'

import {getDefaultTabIndex, validTabIndexOrDefault} from './common'
import {MenuLinksBox, NavMenuLinks} from './components/'
import {useStyles} from './styles'
import {BurgerMenuProps, BurgerMenuTabs} from './types'

// eslint-disable-next-line complexity
export const BurgerMenu: React.FC<BurgerMenuProps> = ({
  navItems = [],
  iconButtonClassName,
  disableAppSelect = false,
  'data-test-id': dataTestId = 'burger-menu',

  activeAppName,
  activePlantId,
  whiteList,
  analytics,
  closeOverlays,
  navItemSelected
}) => {
  const {t} = useTranslation()
  const [isOpen, setOpen] = useState<boolean>(false)
  const classes = useStyles()
  const hasNavItems = navItems.length > 0
  const [tabValue, setTabValue] = useState<BurgerMenuTabs>(
    getDefaultTabIndex(hasNavItems, disableAppSelect)
  )

  useLayoutEffect(() => {
    setTabValue(getDefaultTabIndex(hasNavItems, disableAppSelect))
  }, [disableAppSelect, hasNavItems])

  const onSelect = () => {
    navItemSelected && navItemSelected()
    setOpen(false)
  }

  if (disableAppSelect && !hasNavItems) {
    return null
  }

  const validatedTabValue = validTabIndexOrDefault(tabValue, hasNavItems, disableAppSelect)
  const isMoreThanOneTab = hasNavItems && !disableAppSelect

  return (
    <>
      <HproduceIconButton
        className={classNames(iconButtonClassName, {[classes.activeMenuButton]: isOpen})}
        dataTestId={dataTestId}
        onClick={() => {
          setOpen(!isOpen)
          closeOverlays && !isOpen && closeOverlays()
        }}
        size="medium"
        focusRipple={isOpen}
      >
        {isOpen ? (
          <CloseIcon style={{fontSize: '16px'}} />
        ) : (
          <MenuIcon style={{fontSize: '16px'}} />
        )}
      </HproduceIconButton>
      <Overlay overlayClassName={classes.overlayVisibility} isVisible={isOpen}>
        {!disableAppSelect && (
          <Box bgcolor="background.paper" color="text.primary" borderRadius={8} mt={3} mb={2} p={2}>
            <HProduceDashboardLink />
          </Box>
        )}
        {activeAppName !== 'HConnect' && isMoreThanOneTab && (
          <Tabs
            centered
            indicatorColor="primary"
            textColor="inherit"
            value={validatedTabValue}
            onChange={(e, v: number) => {
              setTabValue(v)
            }}
            className={classes.tabs}
          >
            {hasNavItems && (
              <Tab
                value={BurgerMenuTabs.menu}
                label={t('burgerMenu.menu', 'Menu')}
                className={classes.tab}
              />
            )}
            {!disableAppSelect && (
              <Tab
                value={BurgerMenuTabs.products}
                label={t('burgerMenu.products', 'Products')}
                className={classes.tab}
              />
            )}
          </Tabs>
        )}
        <Box height={24} />
        {hasNavItems && isOpen && validatedTabValue === BurgerMenuTabs.menu && (
          <MenuLinksBox>
            <NavMenuLinks
              navItems={navItems}
              onSelect={onSelect}
              analytics={analytics}
              data-test-id={`${dataTestId}-list`}
            />
          </MenuLinksBox>
        )}
        {!disableAppSelect &&
          whiteList &&
          validatedTabValue === BurgerMenuTabs.products &&
          activeAppName !== 'HConnect' && (
            <AppSelect
              activeAppName={activeAppName}
              activePlantId={activePlantId}
              data-test-id={dataTestId}
              whiteList={whiteList}
            />
          )}
      </Overlay>
    </>
  )
}

import {ButtonBase, ButtonProps, css, styled, Theme} from '@mui/material'
import React, {forwardRef} from 'react'

const ButtonLong = styled(ButtonBase)(
  ({theme}) => css`
    padding: ${theme.spacing(0.5)};
    margin-bottom: ${theme.spacing(0.16)};
    margin-left: ${theme.spacing(0.5)};
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid ${theme.palette.secondary.main};
  `
)

const highlightedButton = (theme: Theme) => css`
  & {
    border: 1px solid ${theme.palette.error.main};
    border-color: ${theme.palette.error.main};
  }
  &:hover {
    border-color: ${theme.palette.error.main};
  }
`
const BasicButton = styled(ButtonBase)<{highlighted: boolean}>(
  ({theme, highlighted}) => css`
    padding: ${theme.spacing(1)};
    border-radius: ${theme.spacing(0.5)};
    background-color: ${theme.palette.grey[50]};
    border: 1px solid ${theme.palette.grey[100]};
    &:hover {
      background: ${theme.palette.grey[100]};
      background-color: ${theme.palette.grey[200]};
    }
    ${highlighted ? highlightedButton(theme) : ''}
  `
)

type TitleButtonProps = {
  className?: string
  onClick(): void
  long?: boolean
  highlighted?: boolean
} & Omit<ButtonProps, 'sx'>

export const TitleButton = forwardRef<HTMLButtonElement, TitleButtonProps>(
  ({long = false, highlighted = false, ...otherProps}, ref) => {
    return long ? (
      <ButtonLong {...otherProps} ref={ref} />
    ) : (
      <BasicButton highlighted={highlighted} {...otherProps} ref={ref} />
    )
  }
)

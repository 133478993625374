import {BurgerMenuTabs} from '../types'

export const getVisibleTabIndices = (
  hasNavItems: boolean,
  disableAppSelect: boolean
): BurgerMenuTabs[] => {
  const result: BurgerMenuTabs[] = []

  // be aware that the order of these checks will define the order of the tabs
  if (hasNavItems) result.push(BurgerMenuTabs.menu)
  if (!disableAppSelect) result.push(BurgerMenuTabs.products)

  return result
}

export const getDefaultTabIndex = (hasNavItems: boolean, disableAppSelect: boolean): number =>
  getVisibleTabIndices(hasNavItems, disableAppSelect)[0] ?? 0

export const validTabIndexOrDefault = (
  tabIndex: number,
  hasNavItems: boolean,
  disableAppSelect: boolean
): number =>
  getVisibleTabIndices(hasNavItems, disableAppSelect).includes(tabIndex)
    ? tabIndex
    : getDefaultTabIndex(hasNavItems, disableAppSelect)

export function splitArray<T>(arr: Array<T>, noOfItems: number, label: string): Array<T> {
  if (!arr) {
    return []
  }
  if (!noOfItems) {
    return arr
  }
  const index = arr.length - noOfItems
  return [
    ...arr.slice(0, index),
    {
      ...arr[index],
      label,
      url: '#',
      dataTestId: 'more-nav-item',
      subItems: arr.slice(index)
    }
  ]
}

import {Toolbar} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React, {ReactNode} from 'react'

import {LayoutConstants, useShellContext} from '../../common'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.default,
    padding: `0 ${theme.spacing(2)}px`
  },
  boxed: {
    width: '100%',
    maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

interface SubheaderProps {
  boxed?: boolean
  children?: ReactNode
}

const Subheader: React.FC<SubheaderProps> = ({children, boxed}) => {
  const {boxed: defaultBoxed} = useShellContext()
  const classes = useStyles()
  const useBoxedLayout = boxed !== undefined ? boxed : defaultBoxed
  return (
    <div className={classNames(classes.container)}>
      <Toolbar className={classNames({[classes.boxed]: useBoxedLayout})} disableGutters>
        {children}
      </Toolbar>
    </div>
  )
}

export default Subheader

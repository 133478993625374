import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined'
import {Box, Checkbox, IconButton, Menu, MenuItem} from '@mui/material'
import clsx from 'clsx'
import React, {useEffect, useState} from 'react'

import Typography from '../../Typography'
import {useStoreColumns} from '../hooks/useStoreColumns'
import {TableColumnType} from '../types'

import {useStyles} from './ColumnSelection.styles'

type ColumnSelectionProps = {
  columns: TableColumnType[]
  filteredColumns: TableColumnType[]
  setFilteredColumns: (filteredColumns: TableColumnType[]) => void
  loading: boolean
  storeFiltersOptions?: {
    tableName: string
    userId: string
  }
}
export const ColumnSelection = ({
  filteredColumns,
  setFilteredColumns,
  columns,
  storeFiltersOptions,
  loading
}: ColumnSelectionProps) => {
  const {classes} = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const {storedColumns} = useStoreColumns(filteredColumns, columns, storeFiltersOptions)

  useEffect(() => {
    !loading &&
      storedColumns &&
      setFilteredColumns(
        columns.filter((column) => (JSON.parse(storedColumns) as string[]).includes(column.field))
      )
  }, [loading])

  const handleHideOrShowColumn = (filteredColumn: TableColumnType) => {
    setFilteredColumns(
      filteredColumns.map((column) => column.field).includes(filteredColumn.field)
        ? filteredColumns.filter((column) => column.field !== filteredColumn.field)
        : [...filteredColumns, filteredColumn]
    )
  }

  const handleColumns = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const allColumnsSelected = filteredColumns.length === columns.length

  const columnSelectMenu = (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem data-test-id={'column-menu-item-all'} style={{padding: '4px 16px'}}>
        <Checkbox
          disableRipple
          color="primary"
          checked={allColumnsSelected}
          onClick={() => setFilteredColumns(allColumnsSelected ? [] : columns)}
          icon={<span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />}
          checkedIcon={<span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />}
          indeterminate={!allColumnsSelected && filteredColumns.length > 0}
          classes={{indeterminate: classes.checkboxIndeterminateIcon}}
        />
        <Typography style={{fontWeight: 500, color: '#00274D'}}>All</Typography>
      </MenuItem>
      <Box style={{padding: '0 10px'}}>
        {columns
          .filter((column) => !column.noColumnSelection)
          .map((column, index) => {
            const mappedFilteredColumn = filteredColumns.map((column) => column.field)
            return (
              <MenuItem
                key={index}
                data-test-id={`column-menu-item-${column.headerName}`}
                style={{padding: '4px 16px'}}
              >
                <Checkbox
                  disableRipple
                  color="primary"
                  checked={mappedFilteredColumn.includes(column.field)}
                  onClick={() => handleHideOrShowColumn(column)}
                  icon={<span className={clsx(classes.checkboxIcon, classes.uncheckedBorder)} />}
                  checkedIcon={
                    <span className={clsx(classes.checkboxIcon, classes.checkboxCheckedIcon)} />
                  }
                  classes={{indeterminate: classes.checkboxIndeterminateIcon}}
                />
                <Typography style={{fontWeight: 500, color: '#00274D'}}>
                  {column.headerName}
                </Typography>
              </MenuItem>
            )
          })}
      </Box>
    </Menu>
  )

  return (
    <Box>
      <IconButton
        onClick={handleColumns}
        style={{height: 'fit-content', color: '#016AD4', boxShadow: 'none', border: 'none'}}
      >
        <ViewWeekOutlinedIcon />
      </IconButton>
      {columnSelectMenu}
    </Box>
  )
}

import {Variant} from '@material-ui/core/styles/createTypography'
import {ClassNameMap} from '@material-ui/styles'
import React, {ElementType} from 'react'

import {mapMdToRender} from '../../Tools'
import Typography from '../Typography'

import {List} from './List'

export interface ElementProps {
  variant: Variant
  element?: ElementType
  className?: string
  gutterBottom?: boolean
  escape?: boolean
}

interface Props {
  body: string | null | undefined
  styles?: ClassNameMap
  paragraphVariant?: Variant
}

const MdRenderer: React.FC<Props> = ({body, styles, paragraphVariant}) => {
  const dataToRender = mapMdToRender(body)

  const typographyElements: {[key: string]: ElementProps} = {
    'header-one': {
      variant: 'h1',
      className: styles?.title
    },
    'header-two': {
      variant: 'h2'
    },
    'header-three': {
      variant: 'h3'
    },
    'header-four': {
      variant: 'h4'
    },
    'header-five': {
      variant: 'h5'
    },
    'header-six': {
      variant: 'h6'
    },
    paragraph: {
      variant: paragraphVariant ?? 'body1',
      className: styles?.title
    },
    'code-block': {
      variant: 'caption',
      element: 'pre',
      className: 'code',
      gutterBottom: true,
      escape: true
    }
  }

  return (
    <>
      {dataToRender.map((item, i) => {
        const itemType = item.type

        if (itemType === 'ordered-list') {
          return <List type={itemType} content={item.content} />
        }
        if (itemType === 'unordered-list') {
          return <List type={itemType} content={item.content} />
        }

        return (
          <Typography
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            data-item-type={itemType}
            component={typographyElements[itemType]?.element}
            paragraph={itemType === 'paragraph'}
            {...typographyElements[itemType]}
            {...(!typographyElements[itemType]?.escape
              ? {dangerouslySetInnerHTML: {__html: item.content.toString()}}
              : {children: item.content.toString()})}
          />
        )
      })}
    </>
  )
}

export {MdRenderer}

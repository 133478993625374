import {Moment} from 'moment'
import React from 'react'

import {DateRange, Dates} from '../../../../common'

import {DatePicker} from './DatePicker'

interface SingleDatePickerProps {
  handleDateChange: (dates: Dates) => void
  date: Moment | null
  onLight?: boolean
  noPastDates?: boolean
  showDatePickerButton?: boolean
  noQuickSelection?: boolean
  availableDateRange?: DateRange
  dateExceptions?: Moment[]
  customMessage?: string
  showTooltip?: boolean
  tooltipLabel?: string
  labelClass?: string
  iconClass?: string
  fullWidth?: boolean
  initiallyOpened?: boolean
  disabled?: boolean
  label?: string
  textClasses?: string
}

export const SingleDatePicker: React.FC<SingleDatePickerProps> = ({
  handleDateChange,
  date,
  onLight,
  showDatePickerButton,
  noQuickSelection,
  availableDateRange,
  dateExceptions,
  customMessage,
  showTooltip,
  tooltipLabel,
  labelClass,
  iconClass,
  fullWidth,
  initiallyOpened,
  label,
  ...props
}) => {
  return (
    <DatePicker
      {...props}
      fullWidth={fullWidth}
      onLight={onLight}
      showDatePickerButton={showDatePickerButton}
      showTooltip={showTooltip}
      tooltipLabel={tooltipLabel}
      noQuickSelection={noQuickSelection}
      hideClearButton
      singleSelection
      startDate={date}
      endDate={date}
      handleDateChange={handleDateChange}
      availableDateRange={availableDateRange}
      dateExceptions={dateExceptions}
      hideApplyButton
      customMessage={customMessage}
      labelClass={labelClass}
      iconClass={iconClass}
      initiallyOpened={initiallyOpened}
      label={label}
    />
  )
}

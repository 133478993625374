import isEmpty from 'lodash/isEmpty'

import {STATISTICS_COOKIE_TOPIC_ID, NECESSARY_COOKIE_TOPIC_ID, isCookieBannerShown} from '..'
import {useOreo, LocaleStorageCookie} from '../common/storage'

export type UserCookieContentType = 'Statistics' | 'All'

const isStatisticsAccepted = (accepted: LocaleStorageCookie) => {
  if (isEmpty(accepted)) {
    return false
  }

  if (!Array.isArray(accepted.acceptedKeys)) {
    return false
  }

  return (
    accepted.acceptedKeys.includes(NECESSARY_COOKIE_TOPIC_ID) &&
    accepted.acceptedKeys.includes(STATISTICS_COOKIE_TOPIC_ID)
  )
}

export const useUserCookieConsent = (cookieConsentType: UserCookieContentType) => {
  const [accepted] = useOreo()

  if (cookieConsentType === 'All') {
    return isCookieBannerShown(accepted)
  }

  if (cookieConsentType === 'Statistics') {
    return isStatisticsAccepted(accepted)
  }
}

import {Box, BoxProps} from '@material-ui/core'
import React from 'react'

export const MenuLinksBox: React.FC<BoxProps> = (props) => (
  <Box
    bgcolor="background.paper"
    color="text.primary"
    borderRadius={8}
    marginBottom={1}
    {...props}
  />
)

import {Box, Theme, makeStyles} from '@material-ui/core'
import moment from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {timeFormatter} from '../../../common'
import Typography from '../Typography'

interface TimeColumnProps {
  label: string
  time: string
}

const TimeColumn: React.FC<TimeColumnProps> = ({label, time}) => {
  const {
    i18n: {language}
  } = useTranslation()

  return (
    <Box display="flex" flexDirection="column">
      <Typography className="title" variant="caption" customColor="textPrimarySoft">
        {label}
      </Typography>
      <Typography className="time" variant="h4" component="span">
        {timeFormatter(moment(time, 'HH:mm:ss'), language)}
      </Typography>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[100]}`,
    '&:hover': {
      '& $textFontFace': {
        textDecoration: 'underline'
      },
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '.MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .time': {
      paddingTop: '6px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.body2.fontSize
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h4.fontSize
      }
    }
  }
}))

interface Props {
  startTimeLabel: string
  startTime: string
  endTimeLabel: string
  endTime: string
  isSingleColumn: boolean
  singleColumnLabel: string
}

export const TimeRangePicker: React.FC<Props> = ({
  startTimeLabel,
  startTime,
  endTimeLabel,
  endTime,
  isSingleColumn = false,
  singleColumnLabel
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-evenly">
        <TimeColumn label={isSingleColumn ? singleColumnLabel : startTimeLabel} time={startTime} />
        {!isSingleColumn ? (
          <>
            <Box mx={2} />
            <TimeColumn label={endTimeLabel} time={endTime} />
          </>
        ) : null}
      </Box>
    </div>
  )
}

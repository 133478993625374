import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  tableScrollButtonRoot: {
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    zIndex: 10,
    minWidth: 0,
    width: '34px',
    border: 'none',
    outline: 'none',
    '&:hover': {
      border: 'none',
      outline: 'none',
      backgroundColor: '#F5F6F7'
    }
  },
  tableScrollButtonLeft: {
    position: 'absolute',
    left: '51px',
    boxShadow: 'rgb(0 0 0 / 11%) 14px 0px 12px -10px'
  },
  tableScrollButtonRight: {
    boxShadow: 'rgb(0 0 0 / 11%) -14px 0px 12px -10px'
  }
}))

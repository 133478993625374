import {useEffect} from 'react'

import {TableFilterType} from '../Filter/TableFilter.types'

export const useStoreFilters = (
  activeFilters: TableFilterType[],
  loading: boolean,
  storeFiltersOptions?: {
    tableName: string
    userId: string
  }
) => {
  const storedActiveFilters = localStorage.getItem(
    `activeFilters-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
  )

  useEffect(() => {
    storeFiltersOptions &&
      !loading &&
      activeFilters.length > 0 &&
      localStorage.setItem(
        `activeFilters-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`,
        JSON.stringify(activeFilters.map((activeFilter) => activeFilter.filterField))
      )

    storedActiveFilters &&
      JSON.parse(storedActiveFilters).length === 0 &&
      localStorage.removeItem(
        `activeFilters-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters, loading])

  return {storedActiveFilters}
}

import {Box} from '@mui/material'
import React from 'react'

import {useShellContext, LayoutConstants} from '../../../common'

interface FooterProps {
  boxed?: boolean
  onDark?: boolean
  sticky?: boolean
  zIndex?: number | string
  children: React.ReactNode
}

export const Footer = React.forwardRef<HTMLDivElement, FooterProps>(
  ({boxed, onDark = true, sticky = false, children, zIndex, ...props}, ref) => {
    const {boxed: defaultBoxed} = useShellContext()
    const boxedLayout = boxed ?? defaultBoxed

    return (
      <Box
        ref={ref}
        sx={{
          '@media print': {
            display: 'none'
          },
          bgcolor: onDark ? 'transparent' : '#ffffff',
          ...(sticky ? {position: 'sticky', bottom: 0} : {}),
          ...(zIndex ? {zIndex: zIndex} : {})
        }}
        {...props}
      >
        <Box
          sx={[
            boxedLayout && {
              maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
              marginLeft: 'auto',
              marginRight: 'auto'
            }
          ]}
        >
          {children}
        </Box>
      </Box>
    )
  }
)

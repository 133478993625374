import {Box, SxProps, Theme} from '@mui/material'
import {nanoid} from 'nanoid'
import {OptionsObject, SnackbarProvider, useSnackbar, VariantType} from 'notistack'
import React from 'react'

export interface NotifyOptions extends OptionsObject {
  onClickClose?: () => void
}

export type NotifyFn = (
  variant: VariantType,
  content: React.ReactNode | string,
  options?: NotifyOptions
) => string

type NotificationContextType = {
  notify: NotifyFn
  closeNotification: (key: string) => void
}

export const NotificationContext = React.createContext<NotificationContextType | undefined>(
  undefined
)

export const useNotification = () => {
  const context = React.useContext(NotificationContext)
  if (!context) throw new Error('Usage of useNotification outside of a NotificationProvider')
  return context
}

const NotificationManager = ({
  children,
  ...optionsObject
}: OptionsObject & {children: React.ReactNode}) => {
  const snackbar = useSnackbar()
  const ref = React.createRef()

  const notify = React.useCallback<NotifyFn>(
    (variant, content, options) => {
      const {onClickClose, ...notistackOptions} = options ?? {}

      const key = nanoid()
      const onClick = () => {
        onClickClose?.()
        snackbar.closeSnackbar(key)
      }

      snackbar.enqueueSnackbar(content, {
        key,
        variant,
        ref,
        onClick,
        ...optionsObject,
        ...notistackOptions
      })
      return key
    },
    [optionsObject, ref, snackbar]
  )

  const closeNotification = React.useCallback(
    (key: string) => {
      return snackbar.closeSnackbar(key)
    },
    [snackbar]
  )

  return (
    <NotificationContext.Provider value={{notify, closeNotification}}>
      {children}
    </NotificationContext.Provider>
  )
}

export interface NotificationProviderProps extends OptionsObject {
  maxSnack?: number
  children?: React.ReactNode
}

export const NotificationProvider = ({
  children,
  maxSnack,
  sx,
  ...options
}: NotificationProviderProps & {sx?: SxProps<Theme>}) => (
  <Box component={SnackbarProvider} maxSnack={maxSnack} sx={sx}>
    <NotificationManager {...options}>{children}</NotificationManager>
  </Box>
)

NotificationProvider.defaultProps = {
  autoHideDuration: 5000,
  maxSnack: 5,
  anchorOrigin: {vertical: 'top', horizontal: 'center'}
}

import {appUrl, hproduce, PLANT_KPI} from '@hconnect/common/hproduce/config'
import {Theme, makeStyles} from '@material-ui/core'
import {DashboardOutlined} from '@material-ui/icons'
import {Typography, Link} from '@mui/material'
import React from 'react'
import {generatePath} from 'react-router'

import {LogoHProduceDarkGreenComponent} from '../../lib2'

const useStyle = makeStyles((theme: Theme) => ({
  hproduceIcon: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04)'
    }
  }
}))

export const HProduceDashboardLink: React.FC<{activePlantId?: string}> = ({activePlantId}) => {
  const classes = useStyle()

  const redirectUrl = activePlantId
    ? generatePath(appUrl(hproduce.url) + PLANT_KPI, {plantId: activePlantId})
    : appUrl(hproduce.url)

  return (
    <Link color={'textPrimary'} underline={'none'} href={redirectUrl}>
      <Typography classes={classes.hproduceIcon}>
        <DashboardOutlined />
        <LogoHProduceDarkGreenComponent />
      </Typography>
    </Link>
  )
}

import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  columnMenuButton: {
    outline: 'none',
    border: 'none',
    boxShadow: 'none',
    padding: 0
  },
  columnHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
    gap: '4px',

    '& .MuiMenuOpen': {
      fill: 'currentcolor'
    },

    '& .MuiSvgIcon-root': {
      fill: 'transparent'
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        fill: 'currentcolor'
      }
    }
  },
  noHover: {
    '& .MuiSvgIcon-root': {
      fill: 'currentcolor'
    }
  }
}))

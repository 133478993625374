import {IconButton, Theme, makeStyles} from '@material-ui/core'
import {KeyboardArrowDown, KeyboardArrowRight} from '@material-ui/icons'
import classNames from 'classnames'
import React, {useEffect, useState} from 'react'

const useStyles = makeStyles((theme: Theme) => ({
  iconExpand: {
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.primary.main,
    '& svg': {
      transition: 'transform 0.2s'
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: `${theme.palette.primary.main} !important`,
      '&.collapsed svg': {
        transform: 'rotate(90deg)'
      },
      '&.expanded svg': {
        transform: 'rotate(-90deg)'
      },
      boxShadow: 'none'
    }
  }
}))

interface Props {
  open: boolean
  onClick?: () => void
}

export const ExpandIcon: React.FC<Props> = ({open, onClick}) => {
  const [isOpen, setIsOpen] = useState<boolean>(open)
  const classes = useStyles()

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleOnClick = () => {
    setIsOpen(!isOpen)
    onClick && onClick()
  }

  const icon = isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />

  return (
    <IconButton
      onClick={() => handleOnClick()}
      className={classNames(classes.iconExpand, isOpen ? 'expanded' : 'collapsed')}
    >
      {icon}
    </IconButton>
  )
}

import {Box, TableCell, TableRow} from '@mui/material'
import React from 'react'

type EmptyResponsiveTableRowProps = {
  emptyMessage: React.ReactNode
  isMobile: boolean
  enableHorizontalScroll: boolean
  noHiddenColumnsNr: number
}
export const EmptyResponsiveTableRow = ({
  enableHorizontalScroll,
  isMobile,
  emptyMessage,
  noHiddenColumnsNr
}: EmptyResponsiveTableRowProps) => {
  return (
    <TableRow component="div">
      <TableCell colSpan={noHiddenColumnsNr} data-test-id="DataTable-empty" align="center">
        <Box style={{width: isMobile || !enableHorizontalScroll ? '100%' : '100vw'}}>
          {emptyMessage}
        </Box>
      </TableCell>
    </TableRow>
  )
}

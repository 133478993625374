import {Checkbox, List, ListItem, ListItemIcon, ListItemText, Paper} from '@material-ui/core'
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {BannerLabels, Topic} from '../../common/interfaces'
import {useOreo} from '../../common/storage'
import {CookieList} from '../CookieList'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 6,
    marginRight: 6,
    marginTop: 16
  },
  topicContainer: {
    backgroundColor: theme.palette.background.paper
  },
  topicItem: {
    fontSize: 26
  },
  topicPrimary: {
    ...theme.typography.body1,
    fontWeight: 800
  },
  iconContainer: {
    minWidth: 30
  },
  topicSecondary: {
    color: theme.palette.secondary.dark
  }
}))

interface TopicListProps {
  topics: Array<Topic>
  labels: BannerLabels
  handleToggle: (topic: Topic) => void
}

export const TopicList: React.FC<TopicListProps> = ({topics, labels, handleToggle}) => {
  const classes = useStyles()
  const [accepted] = useOreo()

  const topicsContent = topics.map((topic) => {
    const labelId = `checkbox-list-label-${topic.id}`
    return (
      <Paper key={topic.id} elevation={2} className={classes.root}>
        <ListItem
          key={topic.id}
          classes={{root: classes.topicContainer}}
          divider
          role={undefined}
          dense
          button
          onClick={() => !topic.readOnly && handleToggle(topic)}
        >
          <ListItemIcon className={classes.iconContainer}>
            <Checkbox
              edge="start"
              checked={!!topic.preSelected}
              tabIndex={-1}
              disableRipple
              disabled={!!topic.readOnly}
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            classes={{primary: classes.topicPrimary, secondary: classes.topicSecondary}}
            primary={topic.title}
            secondary={topic.description}
          />
        </ListItem>
        <CookieList cookies={topic.cookies} labels={labels} />
      </Paper>
    )
  })
  return <List>{topicsContent}</List>
}

import {Close, Warning} from '@mui/icons-material'
import {Alert, AlertTitle, Box, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {LayoutConstants} from '../../common'

type Props = {
  onClose: () => void
}

export const UnsupportedBrowserWarning: React.FC<Props> = ({onClose}) => {
  const {t} = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        height: 120,
        maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
        marginLeft: 'auto',
        marginRight: 'auto'
      }}
    >
      <Alert
        sx={{
          width: '100%',
          whiteSpace: 'pre-line'
        }}
        severity="warning"
        icon={
          <Warning
            fontSize="large"
            sx={{
              alignSelf: 'center'
            }}
          />
        }
        action={
          <IconButton color="inherit" size="small" onClick={() => onClose()} aria-label="close">
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        <AlertTitle>{t('unsupportedBrowserTitle')}</AlertTitle>
        <span style={{display: 'block'}}>{t('unsupportedBrowser')}</span>
        <span style={{display: 'block'}}>{t('unsupportedBrowserHint')}</span>
      </Alert>
    </Box>
  )
}

import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  tableFilterItem: {
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center',
      width: '100%',
      marginLeft: '12px'
    }
  },
  filterListButton: {
    color: '#016AD4',
    fontWeight: 500,
    marginBottom: '10px'
  },
  filterListIcon: {
    color: '#016AD4',
    height: '16px',
    marginRight: '10px',
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      height: 'auto'
    }
  },
  mobileFilterMenu: {
    background: 'white',
    height: '100%',
    width: '100vw',
    marginRight: '-24px',
    position: 'absolute',
    top: '64px',
    left: 0,
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '0 36px 0 24px'
  },
  checkbox: {
    color: 'rgba(0, 39, 77, 0.15)',
    borderRadius: '3px',
    '&:hover': {
      boxShadow: 'none !important'
    },
    '&.Mui-checked': {
      color: '#016AD4 !important'
    },
    '& .MuiCheckbox-root': {
      boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
    }
  },
  checkboxIcon: {
    borderRadius: '3px',
    width: '16px',
    height: '16px',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.15)',
    backgroundColor: '#FBFBFC',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)'
    }
  },
  checkboxCheckedIcon: {
    backgroundColor: '#016AD4',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3'
    }
  },
  checkboxIndeterminateIcon: {
    '& > svg': {
      fill: '#016AD4',
      width: '20px',
      height: '20px'
    }
  },
  uncheckedBorder: {
    border: '1.5px solid rgba(0, 39, 77, 0.15)'
  },
  resetFiltersButton: {
    display: 'flex',
    '&.MuiButton-root': {
      textTransform: 'none',
      whiteSpace: 'nowrap',
      padding: '9px 32px',
      margin: '12px 24px',
      fontSize: '16px',
      fontWeight: 500,
      color: '#00274D',
      letterSpacing: 0,
      border: '1px solid rgba(0, 39, 77, 0.1)',
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
      borderRadius: '6px'
    }
  }
}))

import React from 'react'

import Typography from '../Typography'

import {MDComponent} from './types'

const elements: {[key: string]: string} = {
  'ordered-list': 'ol',
  'unordered-list': 'ul'
}

const List: React.FC<MDComponent> = ({type, content}, props) =>
  React.createElement(
    elements[type || ''],
    props,
    content.map((item, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={i}>
        <Typography variant="body1" dangerouslySetInnerHTML={{__html: item}} />
      </li>
    ))
  )

export {List}

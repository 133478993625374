import {Box, Button, ButtonProps, makeStyles} from '@material-ui/core'
import React from 'react'

import {HCThemeType} from '../HCTheme.types'

import Typography from './Typography'

const useStyles = makeStyles((theme: HCThemeType) => {
  return {
    root: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        border: `1px ${theme.palette.primary.main} solid`,
        borderRadius: '4px',
        padding: theme.spacing(2),
        justifyContent: 'left',
        textTransform: 'none',
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(1, 125, 209, 0.02)',
        '&:hover': {
          boxShadow: theme.shadows[2],
          backgroundColor: 'rgba(1, 125, 209, 0.02)'
        }
      }
    }
  }
})

export interface ActionButtonProps extends ButtonProps {
  icon?: React.ReactNode
}

export const ActionButton: React.FC<ActionButtonProps> = ({children, ...props}) => {
  const c = useStyles()

  return (
    <Button variant="text" {...props} startIcon={props.icon} className={c.root}>
      <Box display="flex" alignItems="center">
        <Typography customVariant="formLabel" color="primary">
          {children}
        </Typography>
      </Box>
    </Button>
  )
}

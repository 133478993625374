import {makeStyles} from '@material-ui/core'
import {PersonOutline} from '@material-ui/icons'
import React, {SyntheticEvent} from 'react'

import HproduceIconButton from './HproduceIconButton'

interface HProduceAccountButtonProps {
  onClick: (event: SyntheticEvent<EventTarget>) => void
  iconButtonClassName?: string
}

const useStyles = makeStyles((theme) => ({
  fill: {
    fill: theme.palette.background.default
  },
  icon: {
    fontSize: '16px'
  }
}))

const HProduceAccountButton: React.FC<HProduceAccountButtonProps> = ({
  onClick,
  iconButtonClassName
}) => {
  const classes = useStyles()

  return (
    <HproduceIconButton
      onClick={onClick}
      className={iconButtonClassName}
      dataTestId="account-menu-icon"
    >
      <PersonOutline className={classes.icon} fill={classes.fill} />
    </HproduceIconButton>
  )
}

export default HProduceAccountButton

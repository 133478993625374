export enum RenderedItemType {
  CodeBlock = 'code-block',
  HeaderOne = 'header-one',
  HeaderTwo = 'header-two',
  HeaderThree = 'header-three',
  HeaderFour = 'header-four',
  HeaderFive = 'header-five',
  HeaderSix = 'header-six',
  OrderedList = 'ordered-list',
  UnorderedList = 'unordered-list',
  Paragraph = 'paragraph'
}

export interface RenderedItem {
  type: RenderedItemType
  content: string[]
}

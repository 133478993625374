import {Box} from '@material-ui/core'
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {MdRenderer} from '../../../Molecules/MdRenderer'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 10
    }
  })
)

interface PolicyInformationProps {
  value: string
}

export const PolicyInformation: React.FC<PolicyInformationProps> = ({value}) => {
  const classes = useStyle()

  return (
    <Box className={classes.root}>
      <MdRenderer body={value} />
    </Box>
  )
}

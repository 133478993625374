import {withStyles} from '@material-ui/core/styles'
import classNames from 'classnames'
import React from 'react'

import {LayoutConstants} from '../../common'

const styles = () => ({
  container: {
    maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
    margin: '0 auto'
  }
})

interface BoxedContainerProps {
  classes: any
  children?: any
}

const BoxedContainer: React.FC<BoxedContainerProps> = ({classes, children}) => (
  <div className={classNames(classes?.container)}>{children}</div>
)

export default withStyles(styles)(BoxedContainer)

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {Button} from '@mui/material'
import classNames from 'classnames'
import React, {RefObject} from 'react'

import {useStyles} from './ScrollButton.styles'

type ScrollButtonProps = {
  scrollRef: RefObject<HTMLDivElement>
  direction: 'left' | 'right'
  tableHeight?: number | string
}
export const ScrollButton = ({scrollRef, tableHeight, direction}: ScrollButtonProps) => {
  const {classes} = useStyles()

  const handleScrollDirection = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({left: direction === 'left' ? -500 : 500, behavior: 'smooth'})
    }
  }

  return (
    <Button
      className={classNames(
        classes.tableScrollButtonRoot,
        direction === 'left' ? classes.tableScrollButtonLeft : classes.tableScrollButtonRight
      )}
      style={{
        height: `calc(${tableHeight} + 1px)`
      }}
      onClick={handleScrollDirection}
    >
      {direction === 'left' ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
    </Button>
  )
}

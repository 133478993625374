import {IconButton, IconButtonProps} from '@material-ui/core'
import React from 'react'

interface NoBorderIconButtonProps extends IconButtonProps {
  onClick: () => void
}

export const NoBorderIconButton: React.FC<NoBorderIconButtonProps> = ({
  children,
  onClick,
  ...props
}) => {
  return (
    <IconButton style={{border: 'none', boxShadow: 'none'}} onClick={onClick} {...props}>
      {children}
    </IconButton>
  )
}

import {TablePagination, TablePaginationProps} from '@mui/material'
import React from 'react'

import {useTableStyles} from './styles'

type ResponsiveTablePaginationProps = TablePaginationProps
export const ResponsiveTablePagination = (props: ResponsiveTablePaginationProps) => {
  const {classes} = useTableStyles()

  const toTablePaginationPage = (p: number): number => p - 1
  const fromTablePaginationPage = (p: number): number => p + 1

  return (
    <TablePagination
      component="div"
      data-test-id="responsive-table-pagination"
      count={props.count}
      page={toTablePaginationPage(props.page)}
      onPageChange={(event, newPage) => {
        props.onPageChange(event, fromTablePaginationPage(newPage))
      }}
      SelectProps={{
        className: classes.tablePaginationSelect
      }}
      nextIconButtonProps={{className: classes.tablePaginationActionIconButton}}
      backIconButtonProps={{className: classes.tablePaginationActionIconButton}}
      rowsPerPage={props.rowsPerPage}
      onRowsPerPageChange={props.onRowsPerPageChange}
      classes={{
        toolbar: classes.tablePaginationToolbar,
        displayedRows: classes.tablePaginationDisplayedRows,
        spacer: classes.tablePaginationSpacer,
        actions: classes.tablePaginationActions
      }}
    />
  )
}

import {Box, Button, Modal, Typography} from '@material-ui/core'
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'

import {MdRenderer} from '../../../Molecules/MdRenderer'
import {BannerProps, Topic} from '../../common/interfaces'
import {useOreo} from '../../common/storage'
import {PolicyInformation} from '../PolicyInformation'
import {TopicList} from '../TopicList'

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    modalRoot: {
      overflow: 'overlay'
    },
    modalContent: {
      zIndex: 10000000,
      backgroundColor: theme.palette.background.paper,
      border: '1px solid',
      borderColor: theme.palette.primary.dark,
      padding: theme.spacing(2, 4, 3)
    },
    buttons: {
      '& > *': {
        margin: theme.spacing(1),
        marginLeft: 0
      }
    }
  })
)

export const Banner: React.FC<BannerProps> = ({
  labels,
  policyInformation1,
  policyInformation2,
  topics,
  onClose
}) => {
  const [accepted, setAccepted] = useOreo()
  const [topicsSelected, setTopicsSelected] = useState<Topic[]>(topics)
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false)
  const onlyNecessaryAllowed = !!labels?.onlyNecessary

  const classes = useStyle()

  const handleToggle = (topic: Topic) => {
    const index = topicsSelected.indexOf(topic)
    const tmpArray = [...topicsSelected]
    if (index !== -1) {
      tmpArray[index].preSelected = !tmpArray[index].preSelected
    }
    setTopicsSelected(tmpArray)
  }

  useEffect(() => {
    const {acceptedKeys} = accepted
    if (acceptedKeys && acceptedKeys.includes('necessary')) {
      const mergedAccepted = topics.map((topic) => {
        const topicIndex = acceptedKeys.indexOf(topic.id)
        return {...topic, preSelected: topicIndex !== -1}
      })
      setTopicsSelected(mergedAccepted)
    }
  }, [topics, accepted])

  const body = (
    <Box className={classes.modalContent} data-test-id="cookie-banner">
      <Typography variant="h2">
        <span aria-label="Cookie" role="img">
          🍪
        </span>
        {` ${labels.title}`}
      </Typography>
      <MdRenderer body={labels.description} />
      {!!settingsOpen && (
        <Box>
          <PolicyInformation value={policyInformation1} />
          <TopicList topics={topicsSelected} handleToggle={handleToggle} labels={labels} />
          <PolicyInformation value={policyInformation2} />
        </Box>
      )}
      <Box className={classes.buttons}>
        {!settingsOpen && (
          <>
            <Button
              data-test-id="cookie-accept-button-close"
              variant="contained"
              color="primary"
              onClick={() => {
                setAccepted({
                  acceptedKeys: topicsSelected.map((topic) => topic.id),
                  date: Date.now()
                })
                onClose()
              }}
            >
              {labels.acceptButton}
            </Button>
            <Button
              data-test-id="cookie-settings-button"
              variant="contained"
              onClick={() => setSettingsOpen(true)}
            >
              {labels.settingsButton}
            </Button>
          </>
        )}
        {settingsOpen && (
          <Button
            data-test-id="cookie-accept-button-open"
            variant="contained"
            onClick={() => {
              setAccepted({
                acceptedKeys: topicsSelected
                  .filter((topic) => !!topic.preSelected)
                  .map((topic) => topic.id),
                date: Date.now()
              })
              onClose()
            }}
          >
            {labels.acceptSelectionButton}
          </Button>
        )}
        {!settingsOpen && onlyNecessaryAllowed ? (
          <Button
            data-test-id="cookie-accept-button-necessary"
            variant="contained"
            onClick={() => {
              setAccepted({
                acceptedKeys: ['necessary'],
                date: Date.now()
              })
              onClose()
            }}
          >
            {labels.onlyNecessary}
          </Button>
        ) : null}
      </Box>
    </Box>
  )
  return (
    <Modal
      open
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
      className={classes.modalRoot}
      onClose={() => onClose()}
    >
      {body}
    </Modal>
  )
}

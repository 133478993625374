import {ButtonBase} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import classNames from 'classnames'
import React, {ReactNode} from 'react'

import Typography from '../../Typography'

const useStyles = makeStyles((theme) => ({
  button: {
    alignSelf: 'stretch',
    color: theme.palette.primary.contrastText,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  navTitle: {
    marginLeft: theme.spacing()
  }
}))

interface TitleNavProps {
  title?: string
  customTitle?: ReactNode
  onClick: () => void
  labelProps?: any
  className?: string
}

const TitleNav: React.FC<TitleNavProps> = ({
  className: classNameProp,
  title,
  labelProps,
  customTitle,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <ButtonBase className={classNames(classes.button, classNameProp)} {...otherProps}>
      {customTitle || (
        <>
          <ArrowBackIcon />
          <Typography className={classes.navTitle} variant="h2" color="inherit" {...labelProps}>
            {title}
          </Typography>
        </>
      )}
    </ButtonBase>
  )
}

export default TitleNav

import {AppBar, Box, Collapse, SxProps, Theme, Toolbar, ToolbarProps} from '@mui/material'
import queryString from 'query-string'
import React, {useState} from 'react'

import {LayoutConstants, useShellContext} from '../../common'

import {UnsupportedBrowserWarning} from './UnsupportedBrowserWarning'

export const isIE11 = !!/Trident\/7\./.exec(navigator.userAgent)
interface HeaderContainerProps {
  className?: string
  toolbarProps?: ToolbarProps
  scrolled?: boolean
  boxed?: boolean
  compact?: boolean
  children?: React.ReactNode
}

const toolbarSx = (compact: boolean, boxed: boolean): SxProps<Theme> => {
  const boxedSx = boxed
    ? {
        maxWidth: LayoutConstants.DEFAULT_CONTENT_WIDTH,
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    : {}

  const compactSx = compact
    ? {
        height: 50
      }
    : {}

  return {
    flexGrow: 1,
    height: 64,
    minHeight: 50,
    transition: 'height .3s ease',
    width: '100%',
    display: 'flex',
    ...boxedSx,
    ...compactSx
  }
}

export const HeaderContainer: React.FC<HeaderContainerProps> = ({
  children,
  className,
  toolbarProps,
  boxed,
  compact,
  scrolled,
  ...props
}) => {
  const {
    boxed: defaultBoxed,
    compact: defaultCompact,
    scrolled: defaultScrolled
  } = useShellContext()
  const [warningOpen, setWarningOpen] = useState(isIE11)
  const useBoxedLayout = boxed !== undefined ? boxed : defaultBoxed
  const useCompactLayout = compact !== undefined ? compact : defaultCompact
  const isScrolled = scrolled !== undefined ? scrolled : defaultScrolled
  const isCarbonBank = queryString
    .parse(window.location.search)
    .ids_return_url?.includes('CarbonBank')
  const addWarningHeight = (height: number) => (warningOpen ? height + 120 : height)

  return (
    <Box
      sx={{
        '@media print': {
          display: 'none'
        }
      }}
    >
      <Box
        sx={{
          background: 'transparent',
          height: useCompactLayout || isScrolled ? addWarningHeight(50) : addWarningHeight(66)
        }}
      />
      <AppBar
        color="default"
        elevation={isScrolled ? 4 : 0}
        position="fixed"
        sx={(theme) => ({
          backgroundColor: isCarbonBank ? 'rgba(242, 245, 247, 1)' : theme.palette.common.white,
          px: {xs: 3, sm: 4, md: 6, lg: 7}
        })}
        className={className}
        {...props}
      >
        <Collapse in={warningOpen}>
          <UnsupportedBrowserWarning onClose={() => setWarningOpen(false)} />
        </Collapse>

        <Toolbar
          disableGutters
          sx={toolbarSx(useCompactLayout || isScrolled, useBoxedLayout)}
          {...toolbarProps}
        >
          {children}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

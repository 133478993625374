import {IconButton, IconButtonProps, makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

import {useMobileBreakPoint} from '../Hooks'

interface HproduceIconButtonProps extends IconButtonProps {
  className?: string
  dataTestId: string
  refContainer?: React.MutableRefObject<null>
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    borderColor: theme.palette.grey[100],
    boxShadow: `0px 2px 2px 0px ${theme.palette.grey[100]}`,
    padding: '11px',
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      borderColor: theme.palette.grey[100],
      boxShadow: `0px 2px 2px 0px ${theme.palette.grey[100]}`
    },
    '&:active': {
      borderColor: theme.palette.grey[100],
      backgroundColor: theme.palette.grey[200],
      boxShadow: 'none'
    }
  },
  iconButtonMobile: {
    '&:hover': {
      borderColor: theme.palette.grey[100],
      backgroundColor: theme.palette.grey[200],
      boxShadow: 'none'
    }
  }
}))

const HproduceIconButton: React.FC<HproduceIconButtonProps> = ({
  className,
  children,
  dataTestId,
  refContainer,
  ...rest
}) => {
  const classes = useStyles()
  const isMobile = useMobileBreakPoint()

  return (
    <IconButton
      className={classNames(classes.iconButton, isMobile && classes.iconButtonMobile, className)}
      data-test-id={dataTestId}
      ref={refContainer}
      {...rest}
    >
      {children}
    </IconButton>
  )
}

export default HproduceIconButton

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {styled, TableSortLabel} from '@mui/material'
import React, {useEffect, useRef} from 'react'

type SortLabelType = {
  field: string
  sortable?: boolean
  sortingOrder?: 'asc' | 'desc'
  onSort: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, field: string) => void
  label: React.ReactNode | string
  sortedBy?: string
  loading?: boolean
  scrollLabel?: string
  setScrollLabel: (label?: string) => void
}

const TableHeaderLabel = styled('div')(({theme}) => ({
  ...theme.typography.caption,
  fontWeight: 700,
  fontSize: 10,
  textTransform: 'uppercase'
}))

export const SortLabel = ({
  field,
  sortable,
  sortingOrder,
  onSort,
  label,
  sortedBy,
  loading,
  scrollLabel,
  setScrollLabel
}: SortLabelType) => {
  const sortLabelRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (sortLabelRef?.current && !loading && scrollLabel === field) {
      sortLabelRef.current.scrollIntoView({inline: 'center', block: 'nearest'})
      setScrollLabel(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSort, loading])

  if (!sortable) {
    return <TableHeaderLabel>{label}</TableHeaderLabel>
  }

  const SortIcon = () => {
    return (
      <KeyboardArrowDownIcon
        style={{
          transform: sortingOrder === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease-in-out',
          ...(sortedBy === field ? {fill: 'currentcolor'} : undefined)
        }}
      />
    )
  }

  return (
    <TableSortLabel
      ref={sortLabelRef}
      data-test-id={`sort-${field}`}
      active={sortedBy === field}
      direction={sortingOrder}
      onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        setScrollLabel(field)
        onSort(event, field)
      }}
      IconComponent={SortIcon}
      style={{verticalAlign: 'top', height: 'min-content'}}
    >
      <TableHeaderLabel>{label}</TableHeaderLabel>
    </TableSortLabel>
  )
}

import {Skeleton, TableCell, TableRow} from '@mui/material'
import React from 'react'

type SkeletonRowsProps = {
  rowsNo: number
  columnsNo: number
  isMobile: boolean
  rowHeight?: number
}

export const SkeletonRows = ({rowsNo, columnsNo, isMobile, rowHeight}: SkeletonRowsProps) => {
  if (rowsNo < 1 || columnsNo < 1) {
    return null
  }

  return (
    <>
      {Array.from({length: rowsNo}, (_, rowIndex) => {
        return (
          <TableRow component="div" key={`skeleton-${rowIndex}`}>
            {Array.from({length: isMobile ? 1 : columnsNo}, (_, columnIndex) => (
              <TableCell
                component="div"
                key={`skeleton-cell-${rowIndex}-${columnIndex}`}
                style={{...(rowHeight ? {height: rowHeight} : {})}}
              >
                {isMobile && (
                  <Skeleton
                    variant="text"
                    sx={{fontSize: '1rem', height: '40px'}}
                    data-test-id={`mobile-skeleton-cell-${rowIndex}-${columnIndex}`}
                  />
                )}
                <Skeleton
                  variant="text"
                  sx={{fontSize: '1rem', height: isMobile ? '100px' : 'auto'}}
                  data-test-id={`skeleton-cell-${rowIndex}-${columnIndex}`}
                />
              </TableCell>
            ))}
          </TableRow>
        )
      })}
    </>
  )
}

import {Button, ButtonProps, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import LockIcon from '@material-ui/icons/Lock'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  progressButtonWrapper: {
    display: 'inline-block',
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    bottom: theme.spacing(),
    left: '50%',
    marginLeft: -12
  }
}))

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
  btnClassName?: string
  progressClassName?: string
}

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  children,
  className: classNameProp,
  btnClassName,
  progressClassName,
  startIcon,
  disabled,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.progressButtonWrapper, classNameProp)}>
      <Button
        disabled={loading || disabled}
        startIcon={loading || disabled ? <LockIcon /> : startIcon}
        {...otherProps}
        className={btnClassName}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          className={classNames(classes.buttonProgress, progressClassName)}
        />
      )}
    </div>
  )
}

export default LoadingButton

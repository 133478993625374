import {AppName, PlantId, WhiteList} from '@hconnect/common/hproduce/types'

export enum BurgerMenuTabs {
  menu,
  products,
  settings
}

export type ActiveAppNameType = AppName | 'HProduce' | 'HConnect'

export type NavItem = {
  label: string
  url: string
  dataTestId?: string
  isDisabled?: boolean
  subItems?: NavItem[]
}

export type BurgerMenuProps = {
  navItems?: NavItem[]
  disableAppSelect?: boolean
  iconButtonClassName?: string
  'data-test-id'?: string

  activeAppName: ActiveAppNameType
  activePlantId?: PlantId
  whiteList?: WhiteList
  analytics?: (route: string) => void
  closeOverlays?: () => void
  navItemSelected?: () => void
}

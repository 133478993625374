import {ThemeOptions, createTheme} from '@mui/material'
import {deepmerge} from '@mui/utils'

import {hpThemeOptions, PRIMARY_MAIN} from './HProduceTheme'

const hCustomOptions: ThemeOptions = {
  components: {
    MuiTypography: {
      variants: [
        {
          props: {variant: 'formLabel'},
          style: {
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '1.166em',
            letterSpacing: '0.0833em',
            textTransform: 'uppercase'
          }
        },
        {
          props: {variant: 'captionBold'},
          style: {
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '1.166em',
            letterSpacing: '0.0666em'
          }
        },
        {
          props: {variant: 'bold'},
          style: {fontWeight: 700}
        }
      ]
    }
  },
  palette: {
    primary: {
      main: PRIMARY_MAIN,
      oldMain: '#29aaff'
    },
    text: {
      primarySoft: '#628390', // not a material-ui color
      secondarySoft: '#99afb7', // not a material-ui color
      link: '#29aaff',
      hint: '#000000'
    },
    grey: {
      old25: '#fbfbfb',
      old50: '#f7f7f7',
      old100: '#e7e7e7'
    }
  }
}

const hConnectThemeOptions = deepmerge(hpThemeOptions, hCustomOptions)

export const HConnectTheme = createTheme(hConnectThemeOptions)

export const overrideHConnectTheme = (options: ThemeOptions) =>
  createTheme(deepmerge(hConnectThemeOptions, options))

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formLabel: true
    captionBold: true
    bold: true
  }
}

declare module '@mui/material' {
  interface Color {
    old25: string
    old50: string
    old100: string
  }
}

declare module '@mui/material/styles' {
  interface TypeText {
    primarySoft: string
    secondarySoft: string
    link: string
    hint: string
  }
  interface TypeTextOptions {
    primarySoft?: string
    secondarySoft?: string
    link?: string
    hint?: string
  }
  interface PaletteColor {
    oldMain?: string
  }
  interface SimplePaletteColorOptions {
    oldMain?: string
  }
}

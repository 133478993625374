import {Box, makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '220px',
    // FIXME: do not use [50], use predefined color
    background: theme.palette.grey[50],
    // TODO: should be #e7e7e7 but is not part of theme, yet. gray-colors are calculated.
    //  maybe UX can change these to #e5e5e5 which would match "theme.palette.grey[100]"
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: '8px',
    borderRadius: 4,
    '&:hover': {
      // TODO: missing hover colors
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200]
    },
    '&>*': {
      borderLeft: `1px solid ${theme.palette.grey[100]}`,
      '&:hover': {
        borderLeftColor: theme.palette.grey[200]
      }
    }
  }
}))

const HoverContainer = (props: any) => {
  const classes = useStyles()
  const {className, ...restProps} = props
  return <Box className={classNames(classes.root, className)} {...restProps} />
}

export default HoverContainer

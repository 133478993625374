import {useEffect, useState} from 'react'

export const useDetectBrowserZoom = () => {
  const [enableScrollButtons, setEnableScrollButtons] = useState(false)

  useEffect(() => {
    function handleZoom() {
      const isFirefox = navigator.userAgent.indexOf('Firefox') !== -1

      setEnableScrollButtons(
        (isFirefox
          ? window.devicePixelRatio * 100
          : Math.round((window.outerWidth / window.innerWidth) * 100)) > 120
      )
    }
    handleZoom()
    window.addEventListener('resize', handleZoom)
    return () => window.removeEventListener('resize', handleZoom)
  }, [])

  return {enableScrollButtons}
}

import React from 'react'

import {OreoContext} from '../components/OreoContext'

export const OREO_LOCAL_STORAGE_ACCEPTED_KEY = `HC-${process.env.REACT_APP_CLIENT_NAME}-oreo:accepted`

export interface LocaleStorageCookie {
  date?: number
  acceptedKeys: string[]
}
export const getAcceptedCookies = (): LocaleStorageCookie => {
  try {
    const localeStorageCookie = window.localStorage.getItem(OREO_LOCAL_STORAGE_ACCEPTED_KEY)
    if (localeStorageCookie) {
      const parsedCookie: LocaleStorageCookie = JSON.parse(localeStorageCookie)
      return Array.isArray(parsedCookie.acceptedKeys) ? parsedCookie : {acceptedKeys: []}
    }
  } catch (error) {
    console.error(error)
  }
  return {acceptedKeys: []}
}

export const useOreo = () => {
  const context = React.useContext(OreoContext)
  if (!context) {
    throw new Error('useOreo must be used within a OreoContextProvider')
  }
  return [context.accepted, context.setValueInLocalStorage]
}

import {useEffect} from 'react'

import {TableColumnType} from '../types'

export const useStorePinnedColumns = (
  columns: TableColumnType[],
  columnOrder?: TableColumnType[],
  storeFiltersOptions?: {
    tableName: string
    userId: string
  }
) => {
  const storedPinnedColumns = localStorage.getItem(
    `activePinnedColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
  )
  useEffect(() => {
    if (columnOrder?.length) {
      const pinnableColumns = columns.filter((column) => !column.noColumnSelection)
      const parsedStoredPinnedColumns =
        storedPinnedColumns && (JSON.parse(storedPinnedColumns) as string[])
      storeFiltersOptions &&
        columnOrder.length !== pinnableColumns.length &&
        localStorage.setItem(
          `activePinnedColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`,
          JSON.stringify(
            columnOrder.map((column) => ({field: column.field, pinDirection: column.pinDirection}))
          )
        )

      parsedStoredPinnedColumns &&
        pinnableColumns.length === columnOrder.length &&
        localStorage.removeItem(
          `activePinnedColumns-${storeFiltersOptions?.tableName}-${storeFiltersOptions?.userId}`
        )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnOrder])

  return {storedPinnedColumns}
}

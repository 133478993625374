import {Grid, GridProps, Stack} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'

import * as C from './components'

type ChildrenRenderProps<T> = {
  onChange: (val: T) => void
}

type Props<T> = {
  label: string
  description?: string
  dark?: boolean
  isSearchable?: boolean
  'data-test-id'?: string
  options?: T[]
  renderValue?: (value?: T) => React.ReactNode
  value: T | undefined
  className?: string
  innerButtonClassName?: string
  searchFieldClassName?: string
  menuListMaxWidth?: number
  error?: boolean
  disabled?: boolean
  renderOption?: (value: T) => React.ReactNode
  filterOption?: (searchTerm: string, value: T) => boolean
  onChange?: (value?: T) => void
  onPopupToggle?: (value: boolean) => void
  children: (options: T[], props: ChildrenRenderProps<T>) => React.ReactNode
} & Omit<GridProps, 'onChange' | 'children'>

export const SelectDropdown = <T,>({
  label,
  description,
  dark = false,
  className,
  innerButtonClassName,
  searchFieldClassName,
  isSearchable = false,
  renderValue = (value?: T) => value as unknown as React.ReactNode,
  onChange = () => undefined,
  filterOption,
  value,
  error,
  options,
  children,
  onPopupToggle,
  disabled,
  menuListMaxWidth,
  ...props
}: Props<T>) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [popupOpened, setPopupOpened] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onPopupToggle?.(popupOpened), [popupOpened])

  const togglePopup = () => setPopupOpened((v) => !v)
  let optionsToRender = options
  if (filterOption) {
    optionsToRender = options?.filter((o) => filterOption(searchTerm, o))
  }
  const onInternalChange = (newSelection: T | undefined) => {
    setPopupOpened(false)
    onChange(newSelection)
    setSearchTerm('')
  }

  const childrenToRender =
    typeof children === 'function'
      ? children(optionsToRender ?? [], {onChange: onInternalChange})
      : children
  return (
    <Grid aria-label={`${label} dropdown`} {...props} className={className}>
      <C.MainButton
        ref={buttonRef}
        onClick={togglePopup}
        highlighted={error}
        disabled={disabled}
        className={innerButtonClassName}
      >
        <Stack sx={{width: '100%'}}>
          <C.Caption variant="caption" dark={dark}>
            {label}
          </C.Caption>
          <C.TitleBox>
            <C.SelectedOptionTypography dark={dark}>
              {renderValue(value)}
            </C.SelectedOptionTypography>
            <C.DownIcon />
          </C.TitleBox>
          {description && (
            <C.CroppedCaption variant="caption" dark={dark}>
              {description}
            </C.CroppedCaption>
          )}
        </Stack>
      </C.MainButton>
      <C.Popover
        open={popupOpened && !disabled}
        anchorEl={buttonRef.current}
        onClose={togglePopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {
            width: (menuListMaxWidth && buttonRef?.current?.clientWidth) || 'auto'
          }
        }}
      >
        {isSearchable && (
          <C.SearchField
            value={searchTerm}
            key={'label-search-field'}
            customClassName={searchFieldClassName}
            onChange={(e) => setSearchTerm(e.target.value)}
            label={label}
          />
        )}
        <C.MenuList
          aria-label={`${label} filter list`}
          data-test-id={`${props['data-test-id']}-filter-list`}
          maxWidth={menuListMaxWidth}
        >
          {childrenToRender}
        </C.MenuList>
      </C.Popover>
    </Grid>
  )
}

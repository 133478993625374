import {alpha} from '@mui/material'
import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.grey[50],
    border: `1.5px solid ${alpha('#00274D', 0.15)}`,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    '&:hover': {
      background: theme.palette.grey[100],
      borderColor: theme.palette.grey[200],
      '& $resetRoot': {
        borderLeft: 'none'
      }
    }
  },
  error: {
    borderColor: theme.palette.error.main
  },
  textfield: {
    height: '100%',
    margin: 0,
    padding: '9px 16px',
    borderRight: `1.5px solid ${alpha('#00274D', 0.15)}`,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },

    '& .MuiInputLabel-formControl': {
      padding: '5px 16px 0 16px',
      color: alpha(theme.palette.secondary.dark, 0.6),
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '12px',
      transform: 'none'
    },
    '& .MuiInputBase-root': {
      alignItems: 'flex-end',
      '& .MuiInputBase-input': {
        padding: 0,
        lineHeight: '28px'
      }
    },
    '&:hover': {
      '&::after,&::before': {
        border: 'none'
      },
      borderRight: 'none'
    }
  },
  icon: {
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    }
  },
  closeRoot: {
    display: 'flex',
    height: '57px'
  },
  close: {
    padding: '10px 15px',
    '& .MuiSvgIcon-root': {
      fontSize: '1rem',
      color: 'black'
    }
  },
  dropdown: {
    background: theme.palette.grey[50],
    [theme.breakpoints.down('md')]: {
      flex: 1,
      width: '100%',
      maxWidth: 'none'
    }
  }
}))

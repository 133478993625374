import {RefObject, useEffect, useState} from 'react'

import {TableColumnType} from '../types'

export const useScrollBoundaries = (
  scrollRef: RefObject<HTMLDivElement>,
  loading: boolean,
  filteredColumns: TableColumnType[],
  isMobile: boolean
) => {
  const [scrollBoundary, setScrollBoundary] = useState<'start' | 'end' | 'between' | undefined>()

  const checkScrollEnd = (scrollLeft: number, clientWidth: number, scrollWidth: number) =>
    Math.abs(scrollLeft + clientWidth - scrollWidth) <= 1

  const detectScrollBoundaries = () => {
    const element = scrollRef.current
    if (element) {
      // rounding scrollLeft because it's changing value when using browser zoom
      const scrollLeft = Math.round(element.scrollLeft)
      const scrollWidth = element.scrollWidth
      const clientWidth = element.clientWidth
      const isScrollable = element.offsetWidth < scrollWidth

      if (!isScrollable) {
        setScrollBoundary(undefined)
        return
      }

      if (scrollLeft === 0) {
        setScrollBoundary('start')
        return
      }

      if (checkScrollEnd(scrollLeft, clientWidth, scrollWidth)) {
        setScrollBoundary('end')
        return
      }

      setScrollBoundary('between')
    }
  }

  const detectIsScrollable = () => {
    const element = scrollRef.current
    const isScrollable = element && element.offsetWidth < element.scrollWidth
    isScrollable ? detectScrollBoundaries() : setScrollBoundary(undefined)
  }

  useEffect(() => {
    if (!loading) {
      detectIsScrollable()
      detectScrollBoundaries()
    }
  }, [loading, filteredColumns, isMobile])

  useEffect(() => {
    const element = scrollRef.current
    if (element) {
      element.addEventListener('scroll', detectScrollBoundaries)
      window.addEventListener('resize', detectIsScrollable)

      return () => {
        element.removeEventListener('scroll', detectScrollBoundaries)
        window.removeEventListener('resize', detectIsScrollable)
      }
    }
  }, [])

  return {scrollBoundary}
}

import React, {createContext, useState} from 'react'

import {
  getAcceptedCookies,
  LocaleStorageCookie,
  OREO_LOCAL_STORAGE_ACCEPTED_KEY
} from '../common/storage'

// todo: types
type OreoContext = any

export const OreoContext = createContext<OreoContext | null>(null)
OreoContext.displayName = 'OreoContext'

export const OreoContextProvider = (props) => {
  const [accepted, setAccepted] = useState<LocaleStorageCookie>(() => {
    const localeStorageCookie = getAcceptedCookies()
    const initialValue: LocaleStorageCookie = {acceptedKeys: []}
    return localeStorageCookie?.acceptedKeys.length > 0 ? localeStorageCookie : initialValue
  })

  const setValueInLocalStorage = (value: LocaleStorageCookie): void => {
    try {
      setAccepted(() => value)
      window.localStorage.setItem(OREO_LOCAL_STORAGE_ACCEPTED_KEY, JSON.stringify(value))
    } catch (error) {
      console.error(error)
    }
  }

  return <OreoContext.Provider value={{accepted, setValueInLocalStorage}} {...props} />
}

import {Button, ButtonProps} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    color: 'rgb(41, 170, 255)',
    fontSize: 14,
    fontWeight: 500,
    height: 36,
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    width: 'auto',
    padding: '7px 8px 7px 8px',
    '&:hover': {
      backgroundColor: 'rgba(41, 170, 255, .2)'
    }
  }
}))

const TextButton: React.FC<ButtonProps> = ({children, ...props}) => {
  const classes = useStyles()
  return (
    <Button classes={{root: classes.root}} {...props}>
      {children}
    </Button>
  )
}

export default TextButton

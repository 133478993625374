import {useRef} from 'react'

import {TableColumnType} from '../types'

export const useColumnOrder = (
  columns: TableColumnType[],
  setColumnOrder: (columnOrder?: TableColumnType[]) => void,
  columnOrder?: TableColumnType[]
) => {
  const tableCellRefs = useRef<{ref: HTMLDivElement | null; field: string}[]>(
    columns.map((column) => ({ref: null, field: column.field}))
  )
  const recalculateColumnsWidth = () =>
    columnOrder
      ?.filter((col) => col.pinDirection !== undefined)
      .map((value, index, array) => {
        const calculateWidth = (arr) =>
          tableCellRefs.current.reduce((acc, curr) => {
            if (arr?.find((column) => column.field === curr?.field)) {
              if (curr?.ref) {
                const currentWidth = curr?.ref?.offsetWidth ?? 0
                return acc + currentWidth
              }
            }
            return acc
          }, 0)

        const columnWidth =
          value.pinDirection === 'left'
            ? calculateWidth(array.slice(0, index))
            : calculateWidth(array.slice(index + 1))

        return columnWidth
          ? {
              field: value.field,
              width: columnWidth
            }
          : {
              field: value.field,
              width: 0
            }
      })

  const handlePinColumn = (
    column: TableColumnType,
    direction?: 'left' | 'right',
    unpinAll?: boolean
  ) => {
    const newOrder = columnOrder ? [...columnOrder] : [...columns]

    if (unpinAll) {
      setColumnOrder(columns)
      return
    }

    if (
      newOrder.map((columnOrder) => columnOrder.field).indexOf(column.field) < 0 ||
      newOrder.map((columnOrder) => columnOrder.field).indexOf(column.field) >= columns.length
    ) {
      return
    }

    const selectedColumnIndex = columns.findIndex((col) => col.field === column.field)

    const calculateLeftOrder = (direction: 'left' | 'right') => {
      const restArray = columnOrder?.filter((order) => order.pinDirection === direction) || []

      const restIndexes = restArray
        .map((arr) => columns.map((col) => col.field).indexOf(arr.field))
        .filter((coll) => coll > -1)
      const lookForAPlaceToInsertSelectedIndex = restIndexes.findIndex(
        (el) => el > selectedColumnIndex
      )

      const formattedIndexes = columns
        .filter((column, index) => restIndexes.includes(index))
        .map(
          (col) =>
            ({
              ...col,
              pinDirection: direction
            }) as TableColumnType
        )

      formattedIndexes.length > 0
        ? formattedIndexes.splice(
            lookForAPlaceToInsertSelectedIndex >= 0
              ? lookForAPlaceToInsertSelectedIndex
              : formattedIndexes.length,
            0,
            columns[selectedColumnIndex]
          )
        : [columns[selectedColumnIndex]]

      return formattedIndexes.length > 0 ? formattedIndexes : [columns[selectedColumnIndex]]
    }
    const dirOrder = direction && calculateLeftOrder(direction)

    if (direction === 'left' && dirOrder) {
      const order = dirOrder.concat(
        newOrder.filter((order) =>
          dirOrder.length > 1
            ? !dirOrder.map((left) => left.field).includes(order.field)
            : dirOrder[0].field !== order.field
        )
      )

      const formattedOrder = order.map((newOrderEl) =>
        newOrderEl.field === column.field ? {...newOrderEl, pinDirection: direction} : newOrderEl
      )

      setColumnOrder([...formattedOrder])
    } else if (direction === 'right' && dirOrder) {
      const order = newOrder
        .filter((order) =>
          dirOrder.length > 1
            ? !dirOrder.map((left) => left.field).includes(order.field)
            : dirOrder[0].field !== order.field
        )
        .concat(dirOrder)

      const formattedOrder = order.map((newOrderEl) =>
        newOrderEl.field === column.field ? {...newOrderEl, pinDirection: direction} : newOrderEl
      )

      setColumnOrder([...formattedOrder])
    } else {
      const order = newOrder.filter(
        (order) => order.pinDirection !== 'left' && order.pinDirection !== 'right'
      )

      const indexesArray = order.map((val) => columns.findIndex((el) => el.field === val.field))

      const findIndex = indexesArray.findIndex((el) => el >= selectedColumnIndex)

      if (findIndex === -1) {
        order.push(column)
      } else {
        order.splice(findIndex, 0, column)
      }

      const finalOrder = newOrder
        .filter((order) => order.pinDirection === 'left' && order.field !== column.field)
        .concat(order)
        .concat(
          newOrder.filter((order) => order.pinDirection === 'right' && order.field !== column.field)
        )

      const formattedOrder = finalOrder.map((newOrderEl) =>
        newOrderEl.field === column.field ? {...newOrderEl, pinDirection: direction} : newOrderEl
      )

      setColumnOrder([...formattedOrder])
    }
  }

  return {handlePinColumn, tableCellRefs, recalculateColumnsWidth}
}

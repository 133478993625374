import {makeStyles} from '@material-ui/core/styles'

import {HCThemeType} from '../../HCTheme.types'

export const useStyle = makeStyles<HCThemeType>((theme) => ({
  root: {
    width: 'auto',
    display: 'block',
    background: theme.palette.background.paper
  },
  paginationCustom: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  tableFooter: {
    '&.MuiTableFooter-root': {
      display: 'flex',
      justifyContent: 'center',
      boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.04)',
      alignItems: 'center',
      padding: '5px'
    }
  },
  overflowScroll: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& tableHead': {
      flexShrink: 0
    },

    '& $tableBody': {
      display: 'block',
      flexGrow: 1,
      overflowY: 'auto'
    }
  },
  loadingRow: {
    display: 'block',
    height: 'auto'
  },
  loadingCell: {
    display: 'block',
    width: '100%',
    padding: 0,
    border: 0
  },
  expandLink: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none'
    }
  },
  clickable: {
    '& > $tableBody > $tableRow': {
      cursor: 'pointer',
      '&:hover': {
        '& $iconExpand': {
          color: 'rgba(0, 55, 77, 0.4)'
        },
        '& $iconExpand.collapsed svg': {
          transform: 'rotate(90deg)'
        },
        '& $iconExpand.expanded svg': {
          transform: 'rotate(-90deg)'
        },
        '@media print': {
          boxShadow: 'none'
        }
      }
    }
  },
  tableHead: {
    display: 'block',
    '& $row': {
      height: theme.spacing(5)
    },
    '& $tableRow': {
      borderTop: 'none',
      background: theme.palette.grey[50],
      cursor: 'default'
    },
    '& $tableCell': {
      paddingTop: `auto ${theme.spacing(1.75)}px auto`,
      color: theme.palette.secondary.dark,
      ...theme.typography.formLabel
    }
  },
  sortableColumn: {
    color: theme.palette.secondary.dark,
    transition: 'color 0.2s',
    '& svg': {
      color: 'inherit !important'
    },
    '&:hover, &:focus': {
      color: theme.palette.primary.dark
    },
    '&.MuiTableSortLabel-active': {
      color: theme.palette.secondary.dark,
      '&:hover, &:focus': {
        color: theme.palette.primary.dark
      }
    }
  },
  tableRow: {
    borderTop: `1px solid ${theme.palette.secondary.light}`,
    '&:first-child': {
      borderTop: 'none'
    },
    display: 'block',
    height: 'auto',
    overflow: 'hidden'
  },
  row: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  tableBody: {
    display: 'block',
    '& $tableRow > $row': {
      minHeight: 88
    }
  },
  tableCell: {
    color: theme.palette.secondary.dark,
    display: 'block',
    border: 'none',
    overflow: 'hidden',
    padding: '4px 14px',
    '&:first-child': {
      paddingLeft: 20
    },
    '&:last-child': {
      paddingRight: 20
    },
    '& > div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& p': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  expandCell: {
    width: theme.spacing(7),
    padding: 0
  },
  iconExpand: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.primary.main,
    '& svg': {
      transition: 'transform 0.2s'
    },
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      '&.collapsed svg': {
        transform: 'rotate(90deg)'
      },
      '&.expanded svg': {
        transform: 'rotate(-90deg)'
      },
      boxShadow: 'none'
    }
  },
  empty: {
    textAlign: 'center',
    display: 'block'
  },
  // Variants:
  inset: {
    '& > $tableHead': {
      '& $row': {
        height: theme.spacing(4)
      }
    },
    '& $tableRow:hover': {
      cursor: 'default'
    }
  },
  compactInset: {
    marginLeft: theme.spacing(9),
    paddingBottom: theme.spacing(2),
    '& > $tableHead': {
      '& $row': {
        background: 'transparent',
        borderBottom: `1px solid ${theme.palette.dividerDark}`
      },
      '& $tableCell': {
        color: theme.palette.text.primarySoft,
        textTransform: 'unset',
        overflowWrap: 'anywhere',
        ...theme.typography.body2
      }
    },
    '& $tableBody $row': {
      minHeight: '48px',
      borderBottom: `1px solid ${theme.palette.divider}`,
      overflowWrap: 'anywhere'
    },
    '& $tableBody $tableRow': {
      borderTop: 'none'
    }
  },
  compact: {
    paddingBottom: theme.spacing(2),
    '& > $tableHead': {
      '& $row': {
        background: 'transparent',
        borderBottom: `1px solid ${theme.palette.dividerDark}`
      },
      '& $tableCell': {
        color: theme.palette.text.primarySoft,
        textTransform: 'unset',
        overflowWrap: 'anywhere',
        ...theme.typography.body1
      }
    },
    '& $tableBody $row': {
      minHeight: '48px',
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    '& $tableBody $tableRow': {
      borderTop: 'none'
    }
  },
  normal: {
    margin: `0 ${theme.spacing(2)}px`,
    '& $loadingRow': {
      marginBottom: theme.spacing(2)
    },
    '& $loadingCell': {
      marginBottom: -theme.spacing(0.75),
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      border: '1px solid transparent'
    },
    '& > $tableHead $tableCell:focus-within': {
      border: `1px solid ${theme.palette.primary.main}`,
      paddingTop: theme.spacing(0.75) - 1,
      paddingLeft: theme.spacing(1.75) - 1
    },
    '& > $tableBody > $tableRow': {
      boxShadow: 'none',
      transition: 'box-shadow 0.2s'
    }
  }
}))

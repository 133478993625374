import {Box, ListItem, ListItemText, Typography} from '@material-ui/core'
import {Theme, makeStyles} from '@material-ui/core/styles'
import React from 'react'

import {BannerLabels, Cookie} from '../../common/interfaces'

const useStyle = makeStyles((theme: Theme) => ({
  provider: {
    paddingLeft: 16,
    paddingBottom: 20
  },
  primary: {
    fontWeight: 800
  },
  secondary: {
    color: theme.palette.secondary.dark
  }
}))

interface CookieListProps {
  cookies: Array<Cookie>
  labels: BannerLabels
}

export const CookieList: React.FC<CookieListProps> = ({cookies, labels}) => {
  const {provider, primary, secondary} = useStyle()
  return (
    <>
      {cookies.map((cookie) => {
        const labelId = `checkbox-list-label-${cookie.id}`
        const providerText = `${labels.providerLabel}: ${cookie.provider}`
        return (
          <Box key={cookie.id}>
            <ListItem role={undefined} data-test-id={`${labelId}-button`}>
              <ListItemText
                id={labelId}
                data-test-id={labelId}
                classes={{primary, secondary}}
                primary={`🍪 ${cookie.title}`}
                secondary={cookie.description}
              />
            </ListItem>
            <Box className={provider}>
              <Typography>{providerText}</Typography>
            </Box>
          </Box>
        )
      })}
    </>
  )
}

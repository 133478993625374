import {
  Grid as AbstractGrid,
  GridProps as AbstractGridProps,
  Theme,
  makeStyles
} from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

export interface GridProps {
  fullHeight?: boolean
}

const useStyle = makeStyles((theme: Theme) => ({
  fullHeight: {
    minHeight: '100vh'
  }
}))

const Grid: React.FC<GridProps & AbstractGridProps> = ({
  fullHeight = false,
  className: classNameProp,
  children,
  ...props
}) => {
  const classes = useStyle()
  const className = classNames(
    {
      [classes.fullHeight]: fullHeight
    },
    classNameProp
  )

  return (
    <AbstractGrid className={className} {...props}>
      {children}
    </AbstractGrid>
  )
}

export default Grid
